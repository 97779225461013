import React from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { AuthContext } from '../App';
import AuthService from '../service/auth';

const ProtectedRoute = (props) => {
    const allowedRoles = props.allowedRoles || []
    const requireNoUser = props.requireNoUser === true
    const bypass = props.bypass === true
    const authContext = React.useContext(AuthContext);;
    const notAuthenticatedPaths = ["user/login", "user/registration"]

    const { companyID } = useParams();

    if (bypass) {
        return props.children;
    }

    if (!authContext) {
        return (notAuthenticatedPaths.includes(props.path)) ? props.children : <Navigate to="/user/login" />;
    } else if (requireNoUser === true) {
        return <Navigate to="/" replace />;
    } else if (allowedRoles.length > 0) {
        const context = Object.assign({}, authContext)
        if (companyID) {
            Object.assign(context, { entityId: companyID })
        }

        if (!AuthService.hasAnyRole(context, allowedRoles)) {
            return <Navigate to="/notEnoughPriviliges" replace />
        }
    }

    return props.children;
};

const NonProtectedRoute = ({ requireNoUser, children }) => {

    const authContext = React.useContext(AuthContext);;

    if (authContext && requireNoUser) {
        return <Navigate to="/" replace />

    }

    return children;
};

export { ProtectedRoute, NonProtectedRoute } 