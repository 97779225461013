import React from 'react';
import { Button, Modal } from 'semantic-ui-react'

class ConfirmationModal extends React.Component {
    render() {
        return (<Modal
            open={true}
            size='small'>
            <Modal.Header>Confirmation</Modal.Header>
            <Modal.Content>
                <p>{this.props.msg}</p>
            </Modal.Content>
            <Modal.Actions>
                <Button size='mini' color='red' onClick={this.props.onConfirm}>
                    Confirm
                </Button>
                <Button size='mini' onClick={this.props.onCancel}>
                    Cancel
                </Button>
            </Modal.Actions>
        </Modal>
        );
    }
}

export default ConfirmationModal