import React from 'react';
import CompanyRepo from '../../utils/repository/companyRepo';
import Loader from '../../components/loader';
import { Header, List } from 'semantic-ui-react';
import RouteC from '../../constnats/routeConstants';
import { Link } from 'react-router-dom';

class Comapanies extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companies: null,
        }
    }

    componentDidMount() {
        CompanyRepo.list(companies => {
            this.setState({ companies: companies })
        })
    }

    render() {
        return <div>
            <Header as="h2" textAlign='center'>Companies</Header>
            {this.state.companies === null ? <Loader fullPage={true} /> :
                <CompaniesList companies={this.state.companies} />}
        </div>

    }
}


function CompaniesList({ companies }) {

    let lastName = ""

    return companies.length === 0 ? <>No companies found</> :
        <List divided verticalAlign='middle'>
            {companies.map(c => {
                const letterItem = c.name.charAt(0).toUpperCase() !== lastName.charAt(0) ?
                    <b>{c.name.charAt(0).toUpperCase()}</b>
                    : <></>

                lastName = c.name.toUpperCase()

                return <List.Item key={c.id}>
                    {letterItem}
                    <Link to={RouteC.COMPANY_PUBLIC + c.id}>
                        <List.Content>{c.name}</List.Content>
                    </Link>
                </List.Item>
            })}
        </List>
}

export default Comapanies