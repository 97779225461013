import React from 'react';
import { Dropdown, Header, List, Segment } from 'semantic-ui-react'
import { CalculationExpUtil } from '../../../../../../../utils/descriptor/calculationExp';
import { DescriptorProps, DescriptorType } from './models/descriptorModel';
import Utils from '../../../../../../../utils/descriptor/descriptorUtils';
import ScaleUtil from '../../../../../../../utils/scaleUtil'
import { Scale } from '../../../../../../../constnats/generalConstants';

const WARN_CLASS = 'warn'
const ERROR_CLASS = 'legend-error'

function FieldOptions({ options }) {
    options = options.map(op => {
        const opName = op.name
        return {
            key: op.idx,
            size: "mini",
            text: opName,
            value: opName,
            onClick: op.onClick
        }
    })

    return (
        <Dropdown
            className='icon fieldOps'
            size="mini"
            icon={options.length > 0 ? "ellipsis vertical" : ""}
            options={options}
            trigger={<></>}
        />
    );

}

function TypeLegend({ descriptor }) {
    return <FieldLegendSpan label="Type" value={descriptor[DescriptorProps.TYPE]} />
}

function InputScaleLegend({ descriptor }) {
    return (
        <FieldLegendSpan
            label="Input Scale"
            value={ScaleUtil.numToTextRepresentation(descriptor[DescriptorProps.INPUT_SCALE], true).toUpperCase()} />
    )
}

function ViewScaleLegend({ descriptor }) {
    let classes = ""
    const viewScale = descriptor[DescriptorProps.VIEW_SCALE]
    if (viewScale !== descriptor[DescriptorProps.INPUT_SCALE]) {
        classes = WARN_CLASS
    }

    return (
        <FieldLegendSpan label="View Scale"
            value={ScaleUtil.numToTextRepresentation(viewScale, true).toUpperCase()}
            classes={classes} />
    )
}

function ScalableLegend({ descriptor }) {
    let classes = ""
    const scalable = descriptor[DescriptorProps.SCALABLE]
    const category = descriptor[DescriptorProps.CATEGORY]

    if (scalable === true &&
        descriptor[DescriptorProps.VIEW_SCALE] === Scale.ACTUAL) {
        classes = WARN_CLASS
    } else if (category && category?.scalable != scalable) {
        classes = ERROR_CLASS
    }

    return (
        <FieldLegendSpan label="Scalable"
            value={scalable === true}
            classes={classes} />
    )
}

function NoteLegend({ descriptor }) {
    return (
        <FieldLegendSpan label="Note"
            value={descriptor[DescriptorProps.NOTE] === true} />
    )
}

function ImportanceLegend({ descriptor }) {
    return (
        <FieldLegendSpan label="Importance"
            value={descriptor[DescriptorProps.IMPORTANCE]} />
    )
}

function CalcTypeLegend({ descriptor }) {
    let classes = ""
    const category = descriptor[DescriptorProps.CATEGORY]
    const categoryCalcType = category?.p2pCalcType
    const descriptorCalcType = descriptor[DescriptorProps.DATA_CALC_TYPE]

    if (categoryCalcType && categoryCalcType !== descriptorCalcType) {
        classes = ERROR_CLASS
    }

    return (
        <FieldLegendSpan
            label="Calc. Method"
            value={descriptorCalcType}
            classes={classes} />
    )
}

function CategoryLegend({ descriptor }) {
    const category = descriptor[DescriptorProps.CATEGORY]
    const categoryName = category?.name

    return category &&
        <FieldLegendSpan label="Category"
            classes={categoryName ? "" : ERROR_CLASS}
            value={categoryName || "ERROR"} />
}

function FieldLegendSpan({ label, value, classes = '' }) {
    return <span className={'descriptorLegendSpan ' + classes}>
        <b>{label}: </b>{String(value)}
    </span >
}

function FieldLegend({ descriptor, additioanLegend }) {

    return (
        <List.Content>
            <Header as='h5'>{descriptor[DescriptorProps.LABEL]}</Header>
            {descriptor.editable === true &&
                <p className='fieldLegendDetails'>
                    <TypeLegend {...{ descriptor }} />
                    {DescriptorType.isNumber(descriptor) && <>
                        <InputScaleLegend {...{ descriptor }} />
                        <ViewScaleLegend  {...{ descriptor }} />
                        <ScalableLegend {...{ descriptor }} />
                        <NoteLegend {...{ descriptor }} />
                    </>}
                    {!DescriptorType.isHeadline(descriptor) && <>
                        <ImportanceLegend {...{ descriptor }} />
                        <CalcTypeLegend {...{ descriptor }} />
                        <CategoryLegend {...{ descriptor }} />
                    </>}

                    <br />
                    {additioanLegend}
                </p>}
        </List.Content>
    )
}

function CalculationSpan({ descriptor, flatDescriptors, calculationProp, calculationName, highlite }) {
    const calculation = descriptor[calculationProp]
    const humanReadableCalc = CalculationExpUtil.originalToHumanReadable(calculation, flatDescriptors)
    const dependancyIds = Utils.dependancyList(descriptor.id, flatDescriptors)
    const allowedDesctrptorsIds = flatDescriptors
        .filter(d => DescriptorType.isNumber(d))
        .filter(d => !dependancyIds.has(d.id))
        .map(d => d.id)
    const includedIds = CalculationExpUtil.getIncludedIds(calculation)
    const invalidCalc = includedIds.some(id => !allowedDesctrptorsIds.includes(id))

    const classes = invalidCalc ? ERROR_CLASS : ''

    return <span className={classes} onMouseEnter={() => highlite(includedIds)}
        onMouseLeave={() => highlite([])}><b>{calculationName}: </b>{humanReadableCalc}</span>
}

function DescriptorView({ descriptor, flatDescriptors, highlite, options, subItems }) {

    let additioanLegend = <></>
    if (Utils.hasCalculation(descriptor)) {
        additioanLegend = <>
            <CalculationSpan
                {...{ highlite, descriptor, flatDescriptors }}
                calculationName="Calculation"
                calculationProp={DescriptorProps.CALCULATION} />
            {Utils.hasSecondaryCalculation(descriptor) && (
                <>
                    <br />
                    <CalculationSpan
                        {...{ highlite, descriptor, flatDescriptors }}
                        calculationName="Secondary Calculation"
                        calculationProp={DescriptorProps.SECONDARY_CALCULATION} />
                </>)
            }
        </>
    }

    return <div style={descriptor.style}>
        <FieldOptions {...{ options }} />
        <FieldLegend {...{ descriptor, additioanLegend }} />
        {descriptor[DescriptorProps.SUBFIELDS]?.length > 0 && <Segment>{subItems}</Segment>}
    </div >
}

class Option {
    constructor(name, onClick) {
        this.name = name
        this.onClick = onClick
    }
}

export { DescriptorView, Option }



