import React from 'react';
import { Divider, List, Segment } from 'semantic-ui-react'
import FieldModal from "./fieldModal";
import { Descriptor, DescriptorProps } from './models/descriptorModel';
import { DescriptorView, Option } from './descriptorView'
import ConfirmationModal from '../../../../../../../components/confirmationModal';
import DescriptorOps from './utils/descriptorOps';
import MoveToModal from './moveToModal';
import MoveBellowModal from './moveBellowModal'
import Alert from '../../../../../../../utils/alert';
import Utils from '../../../../../../../utils/descriptor/descriptorUtils';
import PropTypes from 'prop-types'
import { SchemaField } from '../../../../../../../constnats/schemaConstants';

class DescriptorsForm extends React.Component {

    static MODAL_INVALID_STATE = "Invalide state. Trying to open a new modal before closing the old one. Please do not save the data and contact the administrator."
    static DELETE_FIELD_CONFIRMATION = "The data associated with that field will be permantly lost if you delete the field. Are you sure that you want to delete it?"

    constructor(props) {
        super(props);
        this.state = {
            modal: null,
            desctoptorStyles: []
        }
        this.closeModal = this.closeModal.bind(this);
    }

    highlite(descriptorIds) {
        const desctoptorStyles = {}
        for (const id of descriptorIds) {
            desctoptorStyles[id] = { backgroundColor: "#fcfbc2" }
        }
        this.setState({ desctoptorStyles: desctoptorStyles })
    }

    validateOpenModalReq() {
        if (this.state.modal !== null) {
            Alert.error(DescriptorsForm.MODAL_INVALID_STATE)
        }
    }

    openFieldModal(descriptor, flatDescriptors, onSave) {
        this.validateOpenModalReq()
        this.setState({
            modal: <FieldModal open={true}
                onSave={data => {
                    onSave(data)
                    this.closeModal()
                }}
                onCancel={this.closeModal}
                descriptor={descriptor}
                flatDescriptors={flatDescriptors} />
        })
    }

    openConfirmationModal(onConfirm) {
        this.validateOpenModalReq()
        this.setState({
            modal: <ConfirmationModal
                msg={DescriptorsForm.DELETE_FIELD_CONFIRMATION}
                onCancel={() => this.closeModal()}
                onConfirm={() => {
                    onConfirm()
                    this.closeModal()
                }} />
        })
    }

    openMoveToModal(field, targetDescriptors, onApply) {
        this.validateOpenModalReq()
        this.setState({
            modal: <MoveToModal
                targetDescriptors={targetDescriptors}
                field={field}
                onApply={(targetId) => {
                    onApply(targetId)
                    this.closeModal()
                }}
                onCancel={() => this.closeModal()}
            />
        })
    }


    openMoveBellowModal(descriptor, flatDescriptors, onApply) {
        this.validateOpenModalReq()
        this.setState({
            modal: <MoveBellowModal
                avalableDescriptors={flatDescriptors.filter(d => d.id !== descriptor.id)}
                descriptor={descriptor}
                onApply={(targetId) => {
                    onApply(targetId)
                    this.closeModal()
                }}
                onCancel={() => this.closeModal()}
            />
        })
    }

    closeModal() {
        this.setState({ modal: null })
    }

    getFieldItem(descriptor, subItems, optionsMap, flatDescriptors) {
        const editable = descriptor.editable && !this.props.readOnly

        const options = editable ? [
            new Option("Add Subfield", optionsMap.addSubfield),
            new Option("Move Up", optionsMap.moveUp),
            new Option("Move Down", optionsMap.moveDown),
            new Option("Move Bellow", optionsMap.moveBellow),
            new Option("Move To", optionsMap.moveTo),
            new Option("Remove", optionsMap.remove)]
            : (this.props.readOnly ? [] : [new Option("Move Up", optionsMap.moveUp),
            new Option("Move Down", optionsMap.moveDown)])

        const field = <DescriptorView
            highlite={descriptorIds => this.highlite(descriptorIds)}
            descriptor={descriptor}
            options={options}
            flatDescriptors={flatDescriptors}
            subItems={subItems} />


        return <List.Item style={this.state.desctoptorStyles[descriptor.id] || {}}
            onDoubleClick={(e) => {
                e.stopPropagation();
                editable && optionsMap.update()
            }}
            className={"descriptorItem" + (editable ? " cursorPointer" : "")}
            key={descriptor.id}>
            {field}{this.getDivider(optionsMap.add)}
        </List.Item>
    }

    getDivider(onClick) {
        return this.props.readOnly ? <div className='crosshair inactiveCrosshair'><Divider /></div> :
            <div onClick={onClick} className="crosshair"><Divider /></div>
    }

    getFieldsList(descriptorOps, oneLevelDescriptors, parent, flatDescriptors) {
        const descriptors = oneLevelDescriptors.map((descriptor, idx) => {
            const descriptorId = descriptor.id

            const optionsMap = {
                "update": () =>
                    this.openFieldModal(descriptor, flatDescriptors, newField => descriptorOps.update(newField)),
                "moveUp": () => descriptorOps.moveUp(descriptorId),
                "moveDown": () => descriptorOps.moveDown(descriptorId),
                "moveBellow": () =>
                    this.openMoveBellowModal(descriptor, flatDescriptors,
                        targetDescriptorId => descriptorOps.moveBellow(descriptorId, targetDescriptorId)),
                "remove": () =>
                    this.openConfirmationModal(() => descriptorOps.remove(descriptorId)),
                "add": () =>
                    this.openFieldModal(this.createDescriptor(), flatDescriptors, newField => descriptorOps.add(parent, idx + 1, newField)),
                "addSubfield": () =>
                    this.openFieldModal(this.createDescriptor(), flatDescriptors, newField =>
                        descriptorOps.add(descriptorId, 0, newField))
            }

            const targetOps = descriptorOps.getTargetOptions(descriptorId)
            if (targetOps.length > 0) {
                optionsMap["moveTo"] = () => {
                    this.openMoveToModal(descriptor, targetOps,
                        targetFieldId => descriptorOps.moveTo(descriptorId, targetFieldId))
                }
            }

            const subItems = this.getFieldsList(descriptorOps, descriptor.subFields, descriptorId, flatDescriptors)

            return this.getFieldItem(descriptor, subItems, optionsMap, flatDescriptors)
        })

        return <List verticalAlign='middle'>{descriptors}</List>
    }

    createDescriptor() {
        const descriptor = new Descriptor()
        const scale = this.props[SchemaField.DEF_SCALE]
        descriptor[DescriptorProps.INPUT_SCALE] = scale
        descriptor[DescriptorProps.VIEW_SCALE] = scale
        descriptor[DescriptorProps.IMPORTANCE] = 5
        descriptor[DescriptorProps.DATA_CALC_TYPE] = this.props[SchemaField.DEF_DATA_CALC_TYPE]

        return descriptor
    }

    render() {
        const descriptors = this.props.descriptors
        return (
            <>
                {this.state.modal}
                {descriptors.length > 0 &&
                    <>
                        <b>Model Fields</b>
                        <Segment className='descriptorFields'>
                            {this.getFieldsList(
                                new DescriptorOps(descriptors, this.props.onChange),
                                descriptors, "ROOT", Utils.flatDescriptors(descriptors))}
                        </Segment>
                    </>}
            </>
        );
    }

}


DescriptorsForm.propTypes = {

    /**
     * All schema descriptors.
     */
    descriptors: PropTypes.array.isRequired,

    /**
     * Func to be called if the descriptors are changed.
     */
    onChange: PropTypes.func.isRequired,

    /**
     * True if the descriptors are allowed for .
     */
    readOnly: PropTypes.bool.isRequired,

    /**
     * Default data calculation type. 
     */
    defaultDataCalcType: PropTypes.string.isRequired,

    /**
     * Default input and view scale. 
     */
    defaultScale: PropTypes.number.isRequired
}


export default DescriptorsForm


