/**
 * \p{Sc} Matches any Unicode currency symbol.
 * {@link https://www.compart.com/en/unicode/category/Sc} currency symbols category.
 * ^ inside []: Negates the match, so it matches everything except currency symbols.
 * g: Global flag to match all occurrences in the string.
 * u: Unicode flag to enable Unicode property escapes like \p{Sc}.
 */
const REGEX_EXCLUDE_CURRENCY_SIGNS = /[^\p{Sc}]+/gu

export { REGEX_EXCLUDE_CURRENCY_SIGNS }
