import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "semantic-ui-css/semantic.min.css";
import { Container, Dropdown, Menu, Search } from "semantic-ui-react";
import { AuthContext } from "../../App";
import AuthService from "../../service/auth";
import CompanyRepo from "../../utils/repository/companyRepo";
import { ROLES } from "../../constnats/user";

const anonnymousClientsAllowed = process.env.REACT_APP_ANONYMOUS_CLIENTS_ALLOWED === "true"
const NON_PROD_ENV = process.env.REACT_APP_BACKEND_HOST !== 'https://backend.3d-stockpicker.com/'
//TODO: Export in a global variable in .env file

class HeaderMenu extends React.Component {


    render() {
        const rolesSet = AuthService.getGlobalRoles(this.context)
        const isAuthenticated = rolesSet.size() > 0
        const isEmployee = rolesSet.has(ROLES.EMPLOYEE)

        return (
            <Menu fixed="top" className={'siteHeader' + (NON_PROD_ENV ? ' green-color-header' : '')}>
                <Container>
                    <Menu.Item header className="noPadding">
                        <Link to="/"><AppLogo /></Link>
                    </Menu.Item>
                    {(isAuthenticated || anonnymousClientsAllowed) &&
                        <Menu.Item className="noBorder bold">
                            <Link to="/companies">Companies</Link>
                        </Menu.Item>}
                    <Menu.Menu position='right'>
                        {(isAuthenticated || anonnymousClientsAllowed) &&
                            <CompanySearch
                                publicOnly={!isEmployee}
                                urlPrefix={isEmployee ? "/admin" : ""} />}
                        {isEmployee && <AdministrationMenue isAdmin={rolesSet.has(ROLES.ADMIN)} />}
                        {isAuthenticated ?
                            <UserMenue /> :
                            <Menu.Item className="noBorder bold">
                                <Link to="/user/login">Log In</Link>
                            </Menu.Item>}
                    </Menu.Menu>
                </Container>
            </Menu >
        )
    }


}

function UserMenue() {
    return <>
        <Dropdown text={AuthService.getFullName()} pointing className='link item noBorder'>
            <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/user/profile">Profile</Dropdown.Item>
                <Dropdown.Item as={Link} to="/user/changePassword">Change Password</Dropdown.Item>
                <Dropdown.Item onClick={() => AuthService.logout()}>Logout</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    </>
}

function AdministrationMenue({ isAdmin }) {
    return <>
        <Dropdown text="Administration" pointing className='link item noBorder'>
            <Dropdown.Menu>
                <Dropdown.Item as={Link} to="/admin/companies">Companies</Dropdown.Item>
                <Dropdown.Item as={Link} to="/admin/categories">Categories</Dropdown.Item>
                <Dropdown.Item as={Link} to='/admin/tableUpdates'>Table Updates</Dropdown.Item>
                <Dropdown.Item as={Link} to='/admin/kpis'>KPIs</Dropdown.Item>
                {isAdmin &&
                    <>
                    <Dropdown.Item as={Link} to='/admin/users'>Users</Dropdown.Item>
                        <Dropdown.Item as={Link} to='/admin/newsletter'>Newsletters</Dropdown.Item>
                        <Dropdown.Item as={Link} to='/admin/js-events'>JS Events</Dropdown.Item>
                        {/* <Dropdown.Item as={Link} to='/admin/activityLogs'>Activity Logs</Dropdown.Item> */}
                        <Dropdown.Item
                            href={process.env.REACT_APP_BACKEND_HOST + 'actuator/httptrace'}
                            position="right" >HTTP Trace</Dropdown.Item>
                    </>}
            </Dropdown.Menu>
        </Dropdown>
    </>
}

function AppLogo() {
    return <div className="appLogoWrapper">
        <span className="partOne">3D</span>
        <span className="partTwo">StockPicker</span>
        {/* <img src="/logo-s.png" style={{
            width: "199px",
            marginTop: "6px"
        }} /> */}
    </div>
}


function CompanySearch({ urlPrefix, publicOnly }) {
    const [companies, setCompanies] = React.useState([])
    const [value, setValue] = React.useState("")
    const [isLoading, setIsLoading] = React.useState(false)
    const navigate = useNavigate();

    const handleSearchChange = (e, { value }) => {
        setValue(value)
        if (value.length >= 1) {
            setIsLoading(true)
            CompanyRepo.search(value, companies => {
                setIsLoading(false)
                setCompanies(companies
                    .filter(c => publicOnly ? c.visibility === "PUBLIC" : true)
                    .map(c => { return { title: c.name, id: c.id } }))
            })
        }
    }

    const handleResultSelect = (e, data) => {
        navigate(urlPrefix + "/companies/" + data.result.id)
        setValue("")
    }

    return <Search input={{ icon: 'search' }} loading={isLoading}
        onResultSelect={handleResultSelect} onSearchChange={handleSearchChange}
        results={companies} value={value} minCharacters={1} size="tiny"
        className="companySearch"
    />
}

HeaderMenu.contextType = AuthContext;

export default HeaderMenu