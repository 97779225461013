import { CalculationExpUtil } from './descriptor/calculationExp';
import Equals from './equals';
import CollectionUtils from './collections';
import Utils from './descriptor/descriptorUtils';

class SchemaDiffUtil {

    /**
     * @param  editSchema Required
     * @param  reviewSchema Required
     * @param  ignoreCalcs Required
     * @returns {diffs:[],descriptorPairs[]} not-null
     */
    static schemaDiff(editSchema, reviewSchema, ignoreCalcs = false) {
        const flatEditDecriptors = Utils.flatDescriptors(editSchema.descriptors)
        const flatReviewDecriptors = Utils.flatDescriptors(reviewSchema.descriptors)
        const descriptorPairs = this._mapDescriptors(flatEditDecriptors, flatReviewDecriptors)
        const diffs = this._compareDescriptors(descriptorPairs, flatEditDecriptors, flatReviewDecriptors, ignoreCalcs)

        if (editSchema.name !== reviewSchema.name) {
            diffs.push(new Diff("Different schema names.", editSchema.name, reviewSchema.name))
        }

        return { diffs, descriptorPairs }
    }

    /**
     * @param {[]} editDecriptors  Reuired
     * @param {[]} reviewDecriptors Reuired
     * @returns {[]} not-null
     */
    static _mapDescriptors(editDecriptors, reviewDecriptors) {
        const descriptorPairs = []

        const editDecriptorsCopy = editDecriptors.slice()
        const reviewDecriptorsCopy = reviewDecriptors.slice()

        const reviewMatchHandle = (editDecriptor, reviewDescriptor) => {
            if (reviewDescriptor !== null) {
                const editDecriptorId = editDecriptor.id
                const reviewDescriptorId = reviewDescriptor.id

                SchemaDiffUtil._remove(reviewDecriptorsCopy, reviewDescriptorId)
                SchemaDiffUtil._remove(editDecriptorsCopy, editDecriptorId)
                const editPosition = editDecriptors.findIndex(d => d.id === editDecriptorId)
                const reviewPosition = reviewDecriptors.findIndex(d => d.id === reviewDescriptorId)

                descriptorPairs.push(new DescriptorPair(editDecriptor, reviewDescriptor, editPosition, reviewPosition))
            }
        }

        for (const editDecriptor of editDecriptorsCopy.slice()) {
            reviewMatchHandle(editDecriptor,
                this._mapDescriptorByLabel(editDecriptor, reviewDecriptorsCopy, true))
        }

        for (const editDecriptor of editDecriptorsCopy.slice()) {
            reviewMatchHandle(editDecriptor,
                this._mapDescriptorByLabel(editDecriptor, reviewDecriptorsCopy, false))
        }

        while (CollectionUtils.isEmpty(editDecriptorsCopy) || CollectionUtils.isEmpty(reviewDecriptorsCopy)) {
            if (CollectionUtils.isEmpty(editDecriptorsCopy)) {
                descriptorPairs.push(new DescriptorPair(editDecriptorsCopy.shift(), null))
            }
            if (CollectionUtils.isEmpty(reviewDecriptorsCopy)) {
                descriptorPairs.push(new DescriptorPair(null, reviewDecriptorsCopy.shift()))
            }
        }

        return descriptorPairs
    }

    /**
     * @param {[]} editDecriptor  Reuired
     * @param {[]} reviewDecriptors Reuired
     * @param {boolean} strict If the match has to be strict
     * @returns {[]} not-null
     */
    static _mapDescriptorByLabel(editDecriptor, reviewDecriptors, strict) {
        const editDecriptorLabel = SchemaDiffUtil._normalizeStr(editDecriptor.label);

        let reviewDescriptor = reviewDecriptors
            .find(d => SchemaDiffUtil._normalizeStr(d.label) === editDecriptorLabel)


        if (strict === false) {
            reviewDescriptor = reviewDecriptors
                .find(d => SchemaDiffUtil.areClose(d.label, editDecriptorLabel))
        }

        return reviewDescriptor || null
    }

    /**
     * @param {String} lable1 Required
     * @param {String} lable2 Required
     * @returns boolean
     */
    static areClose(lable1, lable2) {
        lable1 = SchemaDiffUtil._normalizeStr(lable1)
        lable2 = SchemaDiffUtil._normalizeStr(lable2)

        let close;

        if (lable1.includes(" ").length > 1 && lable1.split(" ").length === lable2.split(" ").length) {
            const lable1Parts = lable1.split(" ")
            const lable2Parts = lable2.split(" ")
            let partIdx = 0;
            let tmpClose = true;
            while (partIdx < lable1Parts.length) {
                tmpClose = tmpClose && SchemaDiffUtil.areClose(lable1Parts[partIdx], lable2Parts[partIdx])
            }

            close = tmpClose
        } else {
            const l1L = lable1.length
            const l2L = lable2.length

            const longLabel = l1L >= l2L ? lable1 : lable2
            const shortLabel = l1L < l2L ? lable1 : lable2

            close = (longLabel.replace(shortLabel, "").length < (longLabel.length / 5))
        }


        return close
    }

    static _compareDescriptors(descriptorPairs, flatEditDecriptors, flatReviewDecriptors, ignoreCalcs) {
        const diffs = [];
        for (const descriptorMap of descriptorPairs) {
            const editDescriptor = descriptorMap.editBranchDesc
            const reviewDescriptor = descriptorMap.reviewBranchDesc

            if (editDescriptor === null) {
                diffs.push(new Diff("Missing field.", "", reviewDescriptor.label))
            } else if (reviewDescriptor === null) {
                diffs.push(new Diff("Missing field.", editDescriptor.label, ""))
            } else {
                diffs.push(...this.compareDescriptorPair(descriptorMap, flatEditDecriptors, flatReviewDecriptors, ignoreCalcs))
            }
        }

        return diffs
    }

    /**
     * @param {DescriptorPair} descriptorMap  Required
     * @param {[]} flatEditDecriptors  Required
     * @param {[]} flatReviewDecriptors  Required
     * @returns not-null diffs
     */
    static compareDescriptorPair(descriptorMap, flatEditDecriptors, flatReviewDecriptors, ignoreCalcs) {
        const normalize = descriptor => {
            //TODO fix generaly
            descriptor = JSON.parse(JSON.stringify(descriptor))
            delete descriptor["predefinedValues"]
            if (Object.keys(descriptor.style).length > 0 &&
                descriptor.style["backgroundColor"] === 'inherit') {
                delete descriptor.style["backgroundColor"]
            }

            return descriptor
        }

        const editDescriptor = normalize(descriptorMap.editBranchDesc)
        const reviewDescriptor = normalize(descriptorMap.reviewBranchDesc)

        const diffs = [];

        if (!SchemaDiffUtil._copareStrings(editDescriptor.label, reviewDescriptor.label)) {
            diffs.push(new Diff("Different lables", editDescriptor.label, reviewDescriptor.label))
            return diffs
        }

        const label = editDescriptor.label
        if (editDescriptor.type !== reviewDescriptor.type) {
            diffs.push(new Diff(<>Different field type. Field label: <b>{label}</b></>,
                editDescriptor.type, reviewDescriptor.type))
            return diffs
        }

        diffs.push(...SchemaDiffUtil._subFieldsPropDiff(editDescriptor, reviewDescriptor, label,
            flatEditDecriptors, flatReviewDecriptors))

        const editDescriptorKeys = Object.keys(editDescriptor)
        const reviewDescriptorKeys = Object.keys(reviewDescriptor)
        if (editDescriptorKeys.length !== reviewDescriptorKeys.length) {
            diffs.push(new Diff(<>Different number of props. Field label: <b>{label}</b></>, "", ""))
            return diffs
        }

        for (const editDescriptorKey of editDescriptorKeys) {
            if (editDescriptorKey !== "id" && editDescriptorKey !== "label" && editDescriptorKey !== "subFields" &&
                !Equals.deepEquals(editDescriptor[editDescriptorKey], reviewDescriptor[editDescriptorKey])) {
                if (editDescriptorKey === "calculation" || editDescriptorKey === "secondaryCalculation") {

                    const editDescriptorCalculation = SchemaDiffUtil._normalizeCalculation(editDescriptor[editDescriptorKey], flatEditDecriptors)
                    const reviewDescriptorCalculation = SchemaDiffUtil._normalizeCalculation(reviewDescriptor[editDescriptorKey], flatReviewDecriptors)

                    if (!SchemaDiffUtil._copareStrings(editDescriptorCalculation, reviewDescriptorCalculation) && ignoreCalcs !== true) {
                        diffs.push(new Diff(<>Calculation {"(" + editDescriptorKey + ")"}: <br /><b>{label}</b></>,
                            editDescriptorCalculation, reviewDescriptorCalculation))
                    }

                } else {
                    diffs.push(new Diff("Different props values. Field label:" + label + " Field prop:" + editDescriptorKey,
                        JSON.stringify(editDescriptor[editDescriptorKey]), JSON.stringify(reviewDescriptor[editDescriptorKey])))
                }
            }
        }

        const editPosition = descriptorMap.editPosition
        const reviewPosition = descriptorMap.reviewPosition
        if (diffs.length === 0 && editPosition !== reviewPosition) {
            diffs.push(new Diff(<>Different position. Field label: <b>{label}</b></>,
                "Position:" + editPosition, "Position:" + reviewPosition))
        }

        return diffs
    }

    static _normalizeCalculation(calculationAsPersisted, flatDecriptors) {

        const flatDecriptorsMap = CollectionUtils.arrToMap(flatDecriptors, d => d.id)
        const calcFragments = CalculationExpUtil.strToFragments(calculationAsPersisted)

        for (const calcFragment of calcFragments) {
            if (Array.isArray(calcFragment)) {
                // Possible exception if some of the fields do not exist anymore. It is not supported.
                calcFragment.sort((fieldIdA, fieldIdB) =>
                (SchemaDiffUtil._normalizeStr(flatDecriptorsMap[fieldIdA].label) >
                    SchemaDiffUtil._normalizeStr(flatDecriptorsMap[fieldIdB].label) ? 1 : -1))
            }
        }

        return CalculationExpUtil
            .originalToHumanReadable(CalculationExpUtil.fragmentsToStr(calcFragments), flatDecriptors)
    }

    /**
     * @param {[]} editDescriptor Required
     * @param {[]} reviewDescriptor Required
     * @param {String} label Required
     * @param {[]} flatEditDecriptors Required
     * @param {[]} flatReviewDecriptors Required
     * @returns not-null
     */
    static _subFieldsPropDiff(editDescriptor, reviewDescriptor, label,
        flatEditDecriptors, flatReviewDecriptors) {
        editDescriptor.subFields = editDescriptor.subFields || []
        reviewDescriptor.subFields = reviewDescriptor.subFields || []

        const getLabel = (descriptors, id) => descriptors.find(d => d.id === id).label.trim()
        const getSubFieldLabels = (descriptors, flatDescriptors) =>
            descriptors.subFields.map(d => getLabel(flatDescriptors, d.id))

        const editDescriptorSubFields = getSubFieldLabels(editDescriptor, flatEditDecriptors)
        const reviewDescriptorSubFields = getSubFieldLabels(reviewDescriptor, flatReviewDecriptors)

        const toList = labels => <ol>{labels.map((d, i) => <li key={i}>{d}<br /></li>)}</ol>

        return SchemaDiffUtil._copareStrings(editDescriptorSubFields.join(), reviewDescriptorSubFields.join()) ? [] :
            [new Diff(<>Subfields: <br /><b>{label}</b></>,
                toList(editDescriptorSubFields),
                toList(reviewDescriptorSubFields))]
    }

    /**
     * Removes descriptor by descriptor ID from a descriptors list
     * @param {[]} descriptors Required
     * @param {String} descriptorId Required
     */
    static _remove(descriptors, descriptorId) {
        descriptors.splice(descriptors.findIndex(d => d.id === descriptorId), 1);
    }

    static _copareStrings(str1, str2) {
        return SchemaDiffUtil._normalizeStr(str1) === SchemaDiffUtil._normalizeStr(str2)
    }

    static _normalizeStr(str) {
        return str.trim().toLowerCase()
            .replace(/\s+/g, ' ')
            .replace(/\s*\/\s*/g, "/")
            .replace("–", "-")
            .replace("−", "-")
            .replace("\u2011", "-")
            .replace("\u2010", "-")
    }
}

class Diff {
    static ERROR = "ERROR"
    static WARN = "WARNING"

    constructor(summary, edit = "N/A", review = "N/A", type = Diff.ERROR) {
        this.summary = summary
        this.edit = edit
        this.review = review
        this.type = type
    }
}

class DescriptorPair {
    /**
     * @param  editBranchDesc Optional
     * @param  reviewBranchDesc Optional
     */
    constructor(editBranchDesc, reviewBranchDesc, editPosition, reviewPosition) {
        this.editBranchDesc = editBranchDesc
        this.reviewBranchDesc = reviewBranchDesc
        this.editPosition = editPosition
        this.reviewPosition = reviewPosition
    }
}

export { SchemaDiffUtil, DescriptorPair } 