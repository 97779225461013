import { Card, Icon } from "semantic-ui-react"

function Legend() {
    return <Card fluid>
        <Card.Content>
            <Card.Header className="smallHardHeader">Legend</Card.Header>
            <Card.Description>
                <Icon className='publicCellIIcon' color="yellow" size='small'
                    name='info' /> - Period-To-Period calculated data.<br />
                <Icon className='publicCellIIcon' color="blue" size='small'
                    name='info' /> - The data is reported with label differen than the general label of the row in the table or there is a specifc "Note" related to data.<br />
                <Icon className='publicCellIIcon' color="grey" size='small'
                    name='info' /> - The data is not presented directly in the financial statement but is vertically calculated by 3D based on other reported data.<br />
                <Icon className='publicCellIIcon' color="red" size='small'
                    name='info' /> - Fuzzy Diff between the data in the Latest and Original versions or between reported and Period-To-Period calculated data.
                <b> NOTE:</b> This icon has the priority over the rest.
                This means that the data marked with such icon can also be also repored with different lable, claculated by 3D, or Period-To-Period Calculated.

                <br />
                <Icon className='publicCellIIcon' color="violet" size='small'
                    name='info' /> - There is a value missing in the latest report, which is present in the original report. <b>The value in the cell is copied from the original report</b>.
                <br/>
                <span className="commonInfoLegend">(You can hover the "i" icons in the table to see all details related to them.)</span>
                <br />

                <Icon className='publicCellIIcon' color="grey" size='small'
                    name='file alternate outline' /> - Click to see all files related to the financial statement.

            </Card.Description>
        </Card.Content>
    </Card >

}

export { Legend }