import { CalculationExpUtil } from "../../../../../../../../utils/descriptor/calculationExp";
import get from "../../../../../../../../utils/idFactory";


class Descriptor {
    /**
     * 
     * @param {string} id  Optional 
     * @param {string} label Optional
     * @param {string[]| KeyValue[]} predefinedValues  Optional
     * @param {boolean} editable Specifies if the descriptor is allowed to be modified by the client. Optional
     * @param {css{}} style Optional 
     * @param {boolean} periodToPeriodCalc Optional
     * @param {DescriptorType} type Optional 
     * @param {boolean} dynamicField Optional 
     * @param {int} inputScale One of the specified in {ScaleUtil} Optional
     * @param {int} viewScale One of the specified in {ScaleUtil} Optional
     * @param {{categoyId: string, inverseSign:boolean}} category Optional
     */
    constructor(id, label = "", predefinedValues, editable = true, style = {},
        periodToPeriodCalc = true, type = DescriptorType.NUMBER,
        dynamicField = true, inputScale = 1000, viewScale = 1000, dataCalcType = "NA", category = null) {


        this.id = id === undefined ? get("custom") : id
        this.label = label
        this.editable = editable
        this.style = style
        this.periodToPeriodCalc = periodToPeriodCalc
        this.type = type
        this.inputScale = inputScale
        this.viewScale = viewScale
        this.dataCalcType = dataCalcType
        this.scalable = true
        this.dynamicField = dynamicField
        this.predefinedValues = predefinedValues
        this.calculation = CalculationExpUtil.NO_OP_CALC
        this.secondaryCalculation = CalculationExpUtil.NO_OP_CALC
        this.subFields = []
        this.importance = 0
        this.category = category
    }
}

class DescriptorProps {
    static ID = "id"
    static TYPE = "type"
    static LABEL = "label"
    static INPUT_SCALE = "inputScale"
    static VIEW_SCALE = "viewScale"
    static SCALABLE = "scalable"
    static DATA_CALC_TYPE = "dataCalcType"
    static DYNAMIC = "dynamicField"
    static SUBFIELDS = "subFields"
    static NOTE = "note"
    static IMPORTANCE = "importance"
    static STYLE = "style"
    static CALCULATION = "calculation"
    static SECONDARY_CALCULATION = "secondaryCalculation"
    static CATEGORY = "category"
}

class DescriptorCategoryProps {
    static CATEGORY_NAME = "categoryName"
    static CATEGORY_ID = "categoryId"
    static INVERSESIGN = "inverseSign"
}

class DataCalcType {
    static CUMULATIVE = "CUMULATIVE"
    static NON_CUMULATIVE = "NON_CUMULATIVE"
    static VERTICAL = "VERTICAL"
    static NA = "NA"

    /**
     * @returns not-null list of all data calc types 
     */
    static getAllTypes() {
        return [
            DataCalcType.CUMULATIVE, DataCalcType.NON_CUMULATIVE,
            DataCalcType.VERTICAL, DataCalcType.NA
        ]
    }

}

class DescriptorType {
    static NUMBER = "number"
    static ANY = "any"
    static PERCENT = "percent"
    static HEADLINE = "headline"

    /**
     * @returns not-null list of all descripto types 
     */
    static getAllTypes() {
        return [DescriptorType.NUMBER, DescriptorType.ANY, DescriptorType.PERCENT, DescriptorType.HEADLINE]
    }

    /**
     * @param {{}} descriptor Required 
     * @returns True if the descriptor type is 'headline' otherwice false
     */
    static isHeadline(descriptor) {
        return descriptor.type === DescriptorType.HEADLINE
    }

    /**
     * @param {{}} descriptor Required 
     * @returns True if the descriptor type is 'number' otherwice false
     */
    static isNumber(descriptor) {
        return descriptor.type === DescriptorType.NUMBER
    }

    /**
     * @param {{}} descriptor Required 
     * @returns True if the descriptor type is 'any' otherwice false
     */
    static isAny(descriptor) {
        return descriptor.type === DescriptorType.ANY
    }

    /**
     * @param {{}} descriptor Required 
     * @returns True if the descriptor type is 'percent' otherwice false
     */
    static isPercent(descriptor) {
        return descriptor.type === DescriptorType.PERCENT
    }
}

export { Descriptor, DescriptorType, DescriptorProps, DataCalcType, DescriptorCategoryProps }




