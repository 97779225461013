

class ROLES {
    static ADMIN = "ADMIN_ROLE"
    static READ_ALL_MODELS_AND_REPORTS = "READ_ALL_MODELS_AND_REPORTS"
    static DO_FAST_TRACK_REVIEWS = "DO_FAST_TRACK_REVIEWS"
    static EMPLOYEE = "EMPLOYEE_ROLE"
    static QA = "QA_ROLE"
    static CLIENT = "CLIENT_ROLE"
    static EXPORT_TO_EXCEL = "EXPORT_TO_EXCEL"
    static READ_KPIS = "READ_KPIS"
    static READ_BS = "READ_BS"
    static READ_CF = "READ_CF"
    static READ_SEGMENTS = "READ_SEGMENTS"
    static READ_RESOURCES = "READ_RESOURCES"
    static VIEW_CALCULATED_REPORTS = "VIEW_CALCULATED_REPORTS"
    static READ_REPORTED_AS_LABELS = "READ_REPORTED_AS_LABELS"
    static APPLY_PARTIALLY_REVIEWED_UPDATES = "APPLY_PARTIALLY_REVIEWED_UPDATES"
    static UPDATES_MANAGER = "UPDATES_MANAGER_ROLE"
    static REVIEWS_MANAGER = "REVIEWS_MANAGER_ROLE"
}

export { ROLES }