import React from 'react'
import { Grid, Icon, Label, Menu } from 'semantic-ui-react'
import EntityFactory from './cellEntity'
import { EditField, ResourceUrlsPopup, ViewField } from './columnComponents'
import ConfirmationModal from '../../../../../../components/confirmationModal'
import ReportUtils from '../../../../../../utils/reportUtils'
import { CompanyAuthContext } from '../../../../../../App'
import { ChangeLogModal, ReportChangeDescription } from '../../../../../../components/admin/changeLog'
import Alert from '../../../../../../utils/alert'
import { INVALID } from "../../../../../../constnats/generalConstants"
import CollectionUtils from "../../../../../../utils/collections"
import { DescriptorProps, DescriptorType } from '../schema/descriptors/models/descriptorModel'
import { MetadataField, ReportField, ReportFillingType, SrcFieldValue } from '../../../../../../constnats/reportConstants'
import { getGetPeriod } from '../../../../../../utils/reportResourceUtil'
import ObjCheck from '../../../../../../utils/objCheck'
import Utils from '../../../../../../utils/descriptor/descriptorUtils'
import { ReportChangeConfirmationModalModal } from './reportChangeConfirmationModal'
import { ConfigProps } from '../../../../../../components/financialTables/configuration/constants'

const NOT_FOUND_AFTER_EXTENDED_SEARCH_STYLE = {
    backgroundColor: "#cff5a9", width: "100%",
    height: "100%"
}
const CALCULATION_DISABLED_VIEW_FIELD_STYLE = { textDecoration: "underline", textDecorationColor: "#960b02", textDecorationThickness: "3px" }
const FONT_WEIGHT_BOLD_STYLE = { fontWeight: "bold" }

class LabelsClipboard {
    static SS_KEY = "labelsClipboard"

    static write(labels) {
        sessionStorage.setItem(LabelsClipboard.SS_KEY, JSON.stringify(labels))
    }

    static read() {
        return JSON.parse(sessionStorage.getItem(LabelsClipboard.SS_KEY))
    }
}

function getRiskyDynamicFiledsChanges(entities) {
    const changes = []
    let isNewReport = true
    for (const entity of entities) {
        if (entity.dynamicField === true) {
            let oldData = entity.getOriginalData()
            if (ObjCheck.isNullUndefinedOrEmpty(oldData)) {
                oldData = ""
            } else {
                isNewReport = false
            }

            let newData = entity.getData()
            if (ObjCheck.isNullUndefinedOrEmpty(newData)) {
                newData = ""
            }

            if (oldData !== newData) {
                changes.push({
                    label: entity.getDescriptor()[DescriptorProps.LABEL],
                    old: entity.getOriginalData(),
                    new: entity.getData()
                })
            }
        }
    }

    return isNewReport ? [] : changes
}

class Column extends React.Component {

    constructor(props) {
        super(props);
        this.entryFry = new EntityFactory((descriptorChange, entity) =>
            this.updateEntries(descriptorChange, entity))
        this.realTimeEntries = this.entryFry.create(this.props.descriptors, this.props.report)

        this.state = this.getInitialState()

        this.save = this.save.bind(this)
        this.delete = this.delete.bind(this)
        this.cancel = this.cancel.bind(this)
    }

    getInitialState() {
        return {
            editMode: this.props.isNew && !this.props.readOnly,
            entries: this.realTimeEntries.slice(),
            modal: <></>,
            updatedOn: this.props.report[ReportField.UPDATED_ON],
            dirty: false,
            hasPendingChanges: this.hasPandingChanges(),
            reportSource: this.props.report[ReportField.SRC]
        }
    }

    hasPandingChanges() {
        let hasPendingChange = false

        if (!this.isCalculatedReport()) {
            const changeListener = (descriptorChange, entity) => {
                if (descriptorChange === true) {
                    if (entity.getData() === "-" &&
                        ObjCheck.isNullUndefinedOrEmpty(this.props.report[ReportField.DYNAMIC_FIELDS][entity.descriptorId()])) {
                        //TODO Find more elegant approach
                    } else {
                        hasPendingChange = true
                    }
                }
            }

            /**
             * TODO remove object cloning once sure that the report is not modified in the EntryFactory. Assume it is not but needs to be confirmed.
             */
            const dryRunEntries = new EntityFactory(changeListener)
                .create(this.props.descriptors, JSON.parse(JSON.stringify(this.props.report)))

            for (const entity of dryRunEntries) {
                entity.refresh()
                // TODO More elegant way
                if (entity.valid() === INVALID) {
                    hasPendingChange = true
                }
                if (hasPendingChange === true) {
                    break
                }
            }
        }

        return hasPendingChange
    }

    isCalculatedReport() {
        return this.props.report[ReportField.SRC] === SrcFieldValue.CALCULATED
    }

    componentDidUpdate(prevProps) {
        if(prevProps.report.hash !== this.props.report.hash) {
            this.reset()
        }
    }

    onReportScaleChange(newScale) {
        // TODO This causes many rerenderings. Do it at once
        this.state.entries.filter(e =>
            DescriptorType.isNumber(e.getDescriptor()) &&
            (e.getScalable() || newScale === "0"))
            .forEach(e => e.setFieldInputScale(parseInt(newScale), true))
    }

    onReportSourceChange(entity) {
        this.setState({ reportSource: entity.getData() })
        this.state.entries.forEach(e => e.setSource(null))
    }

    reset() {
        this.entryFry = new EntityFactory((descriptorChange, entity) =>
            this.updateEntries(descriptorChange, entity))
        this.realTimeEntries = this.entryFry.create(this.props.descriptors, this.props.report)
        this.setState(this.getInitialState())
    }

    updateEntries(descriptorChange, entity) {
        if (entity.descriptorId() === DescriptorProps.INPUT_SCALE) {
            this.onReportScaleChange(entity.getData())
        } else if (entity.descriptorId() === ReportField.SRC) {
            this.onReportSourceChange(entity)
        }
        if (descriptorChange === true) {
            this.setDirty(true)
        }

        this.setState({ entries: this.realTimeEntries })
    }

    cellContent(entry) {
        return this.state.editMode === true ?
            <EditField entry={entry} showSource={this.state.reportSource === SrcFieldValue.MIXED} /> : this.viewField(entry)
    }

    viewField(entry) {
        // TODO move to ViewField
        let entryData = entry.getDataInViewFormat()
        const style = {}

        if (ObjCheck.isNullUndefinedEmptyOrDash(entryData) && !this.isCalculatedReport() &&
            !DescriptorType.isHeadline(entry.getDescriptor())) {
            // const culatedReport = this.props.getCalculatedReport(this.props.report.id)
            // if (culatedReport !== null) {
            //     const fieldVal = culatedReport.dynamicFields[entry.descriptorId()]
            //     if (Utils.isNumber(fieldVal) && DescriptorType.isNumber(entry.getDescriptor())) {
            //         entryData = Utils.toNumber(fieldVal) / entry.getViewScale()
            //     } else if (fieldVal !== "-") {
            //         Assert.notNullOrUndefined(fieldVal, "calc value")
            //         entryData = fieldVal
            //     }
            //     if (entryData !== "-") {
            //         Object.assign(style, CALCULATED_VIEW_FILED_STYLE)
            //     }
            // }
        } else if (entry.getMetadata()[MetadataField.CALCS_DISABLED]) {
            Object.assign(style, CALCULATION_DISABLED_VIEW_FIELD_STYLE)
        }

        if (entry.getExtendedSearch() && ObjCheck.isNullUndefinedEmptyOrDash(entryData)) {
            Object.assign(style, NOT_FOUND_AFTER_EXTENDED_SEARCH_STYLE)
        }

        return <ViewField entry={entry} style={style} content={entryData} />
    }


    enableEditMode() {
        if (!this.isCalculatedReport() && !this.props.readOnly) {
            this.setState({ editMode: true })
            //TODO wrap the entities in a struture an execute a single refresh
            this.state.entries.forEach(e => e.refresh())
        }
    }

    disableEditMode() {
        this.setState({ editMode: false })
        this.setDirty(false)
    }

    setDirty(dirty) {
        this.setState({ dirty: dirty })
        this.props.setDirty(dirty)
    }

    save() {
        const entries = this.state.entries
        if (entries.some(e => e.valid() === INVALID)) {
            Alert.warn("The report that you are trying to save was not saved because it contains invalid data." +
                " Please fix it and try again.", "N/A", false)
            return
        }

        const save = () => {
            this.props.save(
                ReportUtils.cellEntitiesToReport(entries, this.props.report),
                () => this.disableEditMode()
            )
        }

        const changes = getRiskyDynamicFiledsChanges(entries)

        if (changes.length > 0) {
            this.openConfirmChangesModal(changes, save)
        } else {
            save()
        }
    }

    delete() {
        this.openConfirmationModal("Are you sure that you want to delete this report?", () => {
            this.props.delete(this.props.report.id, () => this.disableEditMode())
        })
    }

    cancel() {
        this.reset()
        this.disableEditMode()
    }

    copyLables() {
        LabelsClipboard.write(this.state.entries.map(e => {
            return { id: e.descriptorId(), label: e.getMetadata()[MetadataField.REPORTED_AS_LABEL] }
        }))
        Alert.success("Coppied")
    }

    pasteLables() {
        const labels = LabelsClipboard.read()

        if (Array.isArray(labels) && labels.length > 1) {
            const labelsMap = CollectionUtils.arrToMap(labels, e => e.id)
            this.state.entries.forEach(e => {
                const mapEntity = labelsMap[e.descriptorId()]
                const labelToCopy = mapEntity ? mapEntity.label : null
                if (labelToCopy !== null) {
                    const newMetadata = Object.assign({}, e.getMetadata())
                    newMetadata[MetadataField.REPORTED_AS_LABEL] = ObjCheck.isNullOrUndefined(labelToCopy) ? "" : labelToCopy
                    e.setMetadata(newMetadata)
                }
            })
        } else {
            Alert.warn("No lables in the clipboard content.")
        }
    }

    openChangeLogModal() {
        const flatDescriptors = Utils.flatDescriptors(this.props.descriptors)
        this.openModal(<ChangeLogModal
            entityId={this.props.report.id}
            onCancel={() => this.closeModal()}
            descriptionCompType={ReportChangeDescription}
            flatDescriptors={flatDescriptors}
        />
        )
    }

    openConfirmationModal(msg, onConfirm) {
        this.openModal(<ConfirmationModal
            msg={msg}
            onCancel={() => this.closeModal()}
            onConfirm={() => {
                onConfirm()
                this.closeModal()
            }} />
        )
    }

    openConfirmChangesModal(changes, onConfirm) {
        this.openModal(
            <ReportChangeConfirmationModalModal
                {...{ changes }}
                onConfirm={() => {
                    onConfirm()
                    this.closeModal()
                }}
                onCancel={() => this.closeModal()} />
        )
    }

    openModal(modal) {
        this.setState({ modal })
    }

    closeModal() {
        this.setState({ modal: <></> })
    }

    getCellStyle(descriptor, row) {
        const style = JSON.parse(JSON.stringify(descriptor.style))
        if (style.backgroundColor !== undefined) {
            Object.assign(style, FONT_WEIGHT_BOLD_STYLE)
        }

        if (descriptor.note === true) {
            style.color = "#9f9d9d"
        }

        if (this.props.highlightedRows.includes(row)) {
            style.backgroundColor = "#ffff99"
        }

        return style
    }

    cells() {
        const advancedTableCfg = this.props.advancedTableCfg
        return this.state.entries.map((entry, idx) => <Menu.Item
                className={advancedTableCfg[ConfigProps.HORIZONTALS] ? "" : "borderless"}
                style={this.getCellStyle(entry.getDescriptor(), idx + 1)}
                key={entry.descriptorId()}>{this.cellContent(entry)}</Menu.Item>)
    }

    columnLabels() {
        const props = ReportUtils.columnLabelProps(this.props.report[ReportField.TYPE],
            this.isCalculatedReport())
        return props !== null ?
            <Label className="columnLabel" color={props.color} size="mini" floating>{props.label}</Label> : <></>
    }

    options() {
        let options = null
        if (this.state.editMode === true) {
            options = <>
                <Icon color='blue' size='small'
                    className='cursorPointer' name='save' onClick={this.save} />
                <ResourceUrlsPopup listSpecProvider={() => this.listResourceUrlsSpec()}
                    schemaName={this.props.schemaName} allowEdit={true}
                    allowPreview={true} />
                {this.props.report.type === ReportFillingType.ORIGINAL_FILLING &&
                    <Icon color='grey' size='small' className='cursorPointer'
                        name='copy' onClick={() => {
                            const report = ReportUtils
                                .cellEntitiesToReport(this.state.entries, this.props.report)
                            this.props.openCopyDataModal(report)
                        }} />}
                {!this.props.isNew && <Icon color='grey' size='small'
                    className='cursorPointer' name='cancel' onClick={this.cancel} />}
                <Icon color='red' size='small'
                    className='cursorPointer' name='trash' onClick={this.delete} />
            </>
        }

        return options
    }

    columnClass() {
        let clazz = this.state.editMode === true ? "editColumn" : ""
        if (this.state.dirty) {
            clazz = clazz + " changedData"
        } else if (this.state.hasPendingChanges) {
            clazz = clazz + " pendingChanges"
        }

        return clazz
    }

    resourceParams() {
        const entities = this.state.entries
        //TODO Refactor
        const year = entities.find(e => e.descriptorId() === ReportField.YEAR).getData()
        const quoter = entities.find(e => e.descriptorId() === ReportField.PERIOD).getData()
        const type = entities.find(e => e.descriptorId() === ReportField.TYPE).getData()
        const latestFiling = type === ReportFillingType.LATEST_FILLING

        return {
            companyId: this.props.companyId,
            year: latestFiling ? Number(year) + 1 : year,
            quoter: getGetPeriod(quoter),
            excludeOriginalOnly: latestFiling
        }
    }

    listResourceUrlsSpec() {
        return this.resourceParams()
    }

    /**
     * @returns {string} returns the additional class names based on the advanced settings
     */
    getAdditionalColumnClasses() {
        const advancedTableCfg = this.props.advancedTableCfg
        const hasPeriod = !!this.props.report.period;

        const columnBackgroundColorClass = `${hasPeriod ? advancedTableCfg[ConfigProps.PERIOD_CLASSES][this.props.report.period[0]] : ""}`
        const columnNoVerticalsClass = `${advancedTableCfg[ConfigProps.VERTICALS] ? "" : "noVerticals"}`
        
        return `${columnBackgroundColorClass} ${columnNoVerticalsClass}`;
    }

    render() {
        return (
            <Grid.Column style={this.state.editMode ? {} : this.props.columnStyle} className={this.columnClass()}>
                {this.state.modal}
                <Menu className={`relative _${this.props.report.period} ${this.getAdditionalColumnClasses()}`} fluid
                    vertical onDoubleClick={() => this.enableEditMode()} >
                    {this.columnLabels()}
                    {this.cells()}
                </Menu>
                {!this.state.editMode && !this.isCalculatedReport() && <div className='resourcePopupTrigger'>
                    <ResourceUrlsPopup listSpecProvider={() => this.listResourceUrlsSpec()}
                        schemaName={this.props.schemaName} allowEdit={true}
                        allowPreview={true} />
                    {!this.props.readOnly &&
                        this.props.report.type === ReportFillingType.ORIGINAL_FILLING &&
                        <Icon color='grey' size='small'
                            className='cursorPointer' name='copy'
                            onClick={() => this.props.openCopyDataModal(this.props.report)} />}
                    <Icon color='grey' size='small'
                        className='cursorPointer' name='history'
                        onClick={() => this.openChangeLogModal()} />
                </div>}
                <div className='resourcePopupTrigger'>{this.options()}</div>
            </Grid.Column>)
    }
}

Column.contextType = CompanyAuthContext;

export default Column