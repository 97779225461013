import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import 'semantic-ui-css/semantic.min.css'
import 'react-toastify/dist/ReactToastify.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Container, Button, Modal } from "semantic-ui-react";
import Comapanies from "./pages/companies/companies";
import { CompanyAdministration } from "./pages/admin/company/company";
import HeaderMenu from "./components/layout/header";
import { ToastContainer, Zoom } from 'react-toastify';
import LoginForm from './pages/user/login/login';
import RegistrationForm from './pages/user/registration/registration'
import AuthMonitor from './service/authMonitor';
import UserProfile from './pages/user/profile/profile';
import AuthService from './service/auth';
import { AuthContext } from './App';
import { NonProtectedRoute, ProtectedRoute } from './reactRouter/protectedRoute';
import EditUserAdminForm from './pages/admin/user/user';
import Users from './pages/admin/users/users';
import PasswordChangeForm from './pages/user/changePassword/passwordChange';
import { ROLES } from './constnats/user';
import Home from './pages/home/home';
import EventsLog from './pages/admin/jsEvents/jsEvents';
import AboutUs from './pages/info/aboutUs/aboutUs';
import GlobalTableUpdates from './pages/admin/tableUpdates/tableUpdates';
import ComapaniesInternal from './pages/admin/companies/companies';
import Company from './pages/company/company';
import RouteC from './constnats/routeConstants';
import ActivityLogger from './service/activityLogger';
import { ActivityLogs } from './pages/admin/activityLogs/activityLogs';
import { NOT_ENOUGHT_PRIVILEGES_FOR_CLIENT, SITE_NOT_CONVENIENT_ON_MOBILE_DEVICES } from './messages';
import GloalKPIs from './pages/admin/kpis/kpis';
import ContactUs from './pages/contactUs/contactUs';
import Careers from './pages/info/careers/careers';
import AccountTypes from './pages/info/accountTypes/accountTypes';
import Services from './pages/info/services/services';
import TermsAndConditions from './pages/info/termsAndConditions/termsAndConditions';
import PrivacyPolicy from './pages/info/privacyPolicy/privacyPolicy';
import Footer from './components/layout/footer';
import UserActivation from './pages/user/activation/activation';
import NewsletterFrom from './pages/admin/newsletter/newsletter';
import ResourceVewer from './pages/resources/preview/resourceViewer'
import LoginPrompt from './components/loginPromptModal';
import Categories from './pages/admin/categories/categories';

const anonnymousClientsAllowed = process.env.REACT_APP_ANONYMOUS_CLIENTS_ALLOWED === "true"
const ON_MOBILE = window.screen.width <= 800

let loginPromptExecuted = false


function configActivityLogger() {
    if (AuthService.hasGlobalRole(ROLES.EMPLOYEE)) {
        ActivityLogger.enable()
    }
}

class App extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            auth: AuthService.getAuthContext(),
            issueComponent: <></>,
            loginPrompt: <></>,
            navigateTo: <></>
        }

        AuthMonitor.registerAuthChangeListener(() => {
            this.setState({ auth: AuthService.getAuthContext() })
            configActivityLogger()
        })

        AuthMonitor.registerIssueListener(issue =>
            this.setState({
                issueComponent: <Issue msg={issue.msg} resolve={() => {
                    this.setState({ issueComponent: <></> })
                    setTimeout(() => issue.resolve(), 500)
                }} />
            }))

        configActivityLogger()
    }

    scheduleLoginPrompt() {
        setTimeout(() => {
            if (loginPromptRequired()) {
                if (loginPromptAppropriate()) {
                    loginPromptExecuted = true
                    this.setState({
                        loginPrompt: <LoginPrompt onClose={() => {
                            this.setState({ loginPrompt: <></> })
                            loginPromptExecuted = true
                        }} />
                    })
                } else {
                    this.scheduleLoginPrompt()
                }
            }
        }, 1000 * 20)
    }

    render() {

        if (loginPromptRequired()) {
            this.scheduleLoginPrompt()
        }

        return (
            <AuthContext.Provider value={this.state.auth}>
                {this.state.issueComponent}

                <ToastContainer transition={Zoom} className="toastify3DWrapper" />
                <div className='routerWrapper'>
                    <BrowserRouter>
                        <MobileMsgModal />
                        {this.state.loginPrompt}
                        {/* {this.state.navigateTo} */}
                        <HeaderMenu />
                        <Routes>
                            <Route path="/" element={
                                <ProtectedRoute bypass={anonnymousClientsAllowed}>
                                    {AuthService.hasRole(this.state.auth, ROLES.EMPLOYEE) ?
                                        <Container children={<Home />} className="bodyLayoutWide" /> :
                                        <Container text children={<Comapanies />} className="bodyLayout" />}
                                </ProtectedRoute>} />
                            <Route path="companies" element={
                                <ProtectedRoute bypass={anonnymousClientsAllowed}
                                    allowedRoles={[ROLES.EMPLOYEE, ROLES.CLIENT]}>
                                    <Container text children={<Comapanies />} className="bodyLayout" />
                                </ProtectedRoute>} />
                            <Route path="/admin/companies" element={
                                <ProtectedRoute allowedRoles={[ROLES.EMPLOYEE]}>
                                    <Container children={<ComapaniesInternal />} className="bodyLayout" />
                                </ProtectedRoute>} />
                            <Route path="/admin/categories" element={
                                <ProtectedRoute allowedRoles={[ROLES.EMPLOYEE]}>
                                    <Container children={<Categories />} className="bodyLayout" />
                                </ProtectedRoute>} />
                            <Route path="/admin/newsletter" element={
                                <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
                                    <Container children={<NewsletterFrom />} className="bodyLayout" />
                                </ProtectedRoute>} />
                            <Route path={RouteC.EDIT_COMPANY_CONFIG_INTERNAL + ":companyID"} element={
                                <ProtectedRoute allowedRoles={[ROLES.EMPLOYEE]}>
                                    <Container children={<CompanyAdministration />} className="bodyLayoutWide" />
                                </ProtectedRoute>}  >
                                <Route path=":levelOneTab" element={<ProtectedRoute>
                                    <Container children={<CompanyAdministration />} className="bodyLayoutWide" />
                                </ProtectedRoute>}>
                                    <Route path=":levelTwoTab" element={<ProtectedRoute>
                                        <Container children={<CompanyAdministration />} className="bodyLayoutWide" />
                                    </ProtectedRoute>} />
                                </Route>
                            </Route>   
                            <Route path="companies/:companyID" element={
                                <ProtectedRoute allowedRoles={[ROLES.EMPLOYEE, ROLES.CLIENT]} bypass={anonnymousClientsAllowed}>
                                    <Container children={<Company />} className="bodyLayoutWide" />
                                </ProtectedRoute>} />
                            <Route path="user/login" element={
                                <ProtectedRoute path="user/login" requireNoUser={true}>
                                    <Container text children={<LoginForm />} className="bodyLayout" />
                                </ProtectedRoute>} />
                            <Route path="user/activation/:code" element={
                                <NonProtectedRoute>
                                    <Container text children={<UserActivation />} className="bodyLayout" />
                                </NonProtectedRoute>} />
                            <Route path="info/aboutUs" element={
                                <NonProtectedRoute >
                                    <Container text children={<AboutUs />} className="bodyLayout" />
                                </NonProtectedRoute>} />
                            <Route path="contactUs" element={
                                <NonProtectedRoute >
                                    <Container text children={<ContactUs />} className="bodyLayout" />
                                </NonProtectedRoute>} />
                            <Route path="info/careers" element={
                                <NonProtectedRoute >
                                    <Container text children={<Careers />} className="bodyLayout" />
                                </NonProtectedRoute>} />
                            <Route path="info/accountTypes" element={
                                <NonProtectedRoute >
                                    <Container text children={<AccountTypes />} className="bodyLayout" />
                                </NonProtectedRoute>} />
                            <Route path="info/services" element={
                                <NonProtectedRoute >
                                    <Container text children={<Services />} className="bodyLayout" />
                                </NonProtectedRoute>} />
                            <Route path="info/termsAndConditions" element={
                                <NonProtectedRoute >
                                    <Container text children={<TermsAndConditions />} className="bodyLayout" />
                                </NonProtectedRoute>} />
                            <Route path="info/privacyPolicy" element={
                                <NonProtectedRoute  >
                                    <Container text children={<PrivacyPolicy />} className="bodyLayout" />
                                </NonProtectedRoute>} />
                            <Route path="user/registration" element={
                                <ProtectedRoute path="user/registration" requireNoUser={true}>
                                    <Container text children={<RegistrationForm />} className="bodyLayout" />
                                </ProtectedRoute>} />
                            <Route path="user/profile" element={
                                <ProtectedRoute>
                                    <Container text children={<UserProfile />} className="bodyLayout" />
                                </ProtectedRoute>} />
                            <Route path="resources/preview/:query" element={
                                <ProtectedRoute bypass={anonnymousClientsAllowed}>
                                    <Container fluid children={<ResourceVewer />} />
                                </ProtectedRoute>} />
                            <Route path="admin/users/:userID" element={
                                <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
                                    <Container text children={<EditUserAdminForm />} className="bodyLayout" />
                                </ProtectedRoute>} />
                            <Route path="admin/js-events" element={
                                <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
                                    <Container children={<EventsLog />} className="bodyLayoutWide" />
                                </ProtectedRoute>} />
                            <Route path="admin/users" element={
                                <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
                                    <Container children={<Users />} className="bodyLayout" />
                                </ProtectedRoute>} />
                            <Route path="user/changePassword" element={
                                <ProtectedRoute>
                                    <Container text children={<PasswordChangeForm />} className="bodyLayout" />
                                </ProtectedRoute>} />
                            <Route path="admin/tableUpdates" element={
                                <ProtectedRoute allowedRoles={[ROLES.EMPLOYEE]}>
                                    <Container children={<GlobalTableUpdates />} className="bodyLayoutWide" />
                                </ProtectedRoute>} />
                            <Route path="admin/kpis" element={
                                <ProtectedRoute allowedRoles={[ROLES.EMPLOYEE]}>
                                    <Container children={<GloalKPIs />} className="bodyLayoutWide" />
                                </ProtectedRoute>} />
                            <Route path="admin/activityLogs" element={
                                <ProtectedRoute allowedRoles={[ROLES.ADMIN]}>
                                    <Container children={<ActivityLogs />} className="bodyLayoutWide" />
                                </ProtectedRoute>} />
                            <Route path="notEnoughPriviliges" element={
                                <Container text children={<p>{NOT_ENOUGHT_PRIVILEGES_FOR_CLIENT}</p>}
                                    className="bodyLayout" />} />
                            <Route path="entityNotFound" element={
                                <Container text children={<p>The entity that you are trying to open does not exist anymore!</p>}
                                    className="bodyLayout" />} />
                            <Route path="*" element={
                                <Container text children={<p>Sorry, That Page Doesn't Exist!</p>}
                                    className="bodyLayout" />} />
                        </Routes>

                        <Footer />
                    </BrowserRouter>
                </div>
            </AuthContext.Provider >
        )
    }
}


function loginPromptRequired() {
    try {
        return !AuthService.isAuthenticated() && !loginPromptExecuted
    } catch (error) {
        console.error(error)
        return false
    }
}


function loginPromptAppropriate() {
    try {
        const currentUrl = window.location.href;
        return !currentUrl.includes("login") && !currentUrl.includes("registration")
    } catch (error) {
        console.error(error)
        return false
    }
}


function Issue(props) {
    return <div className="issue" >
        {props.msg}
        <Button size='mini' floated='right' color='green' onClick={() => { props.resolve() }
        }> Retry</Button >
    </div >
}

function MobileMsgModal() {
    const [open, setOpen] = useState(ON_MOBILE)

    return (
        <Modal size="fullscreen" open={open}>
            <Modal.Header>Not optimized for mobile devices</Modal.Header>
            <Modal.Content> {SITE_NOT_CONVENIENT_ON_MOBILE_DEVICES}</Modal.Content>
            <Modal.Actions>
                <Button size="mini" onClick={() => setOpen(false)}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    )
}



ReactDOM.render(<App />, document.getElementById('root'));


