import React from 'react';
import { Header, Label, Icon } from 'semantic-ui-react'
import { Link } from "react-router-dom";
import CompanyRepo from '../../../utils/repository/companyRepo';
import Loader from '../../../components/loader';
import RouteC from '../../../constnats/routeConstants';
import CompaniesTable from '../../../components/admin/company/companiesTable';
import { SelectField } from '../../../components/form/fields';
import KeyValue from '../../../structures/keyValePair';

const sortFuncs = {
    byName: (a, b) => {
        if (a.name < b.name) {
            return -1;
        }
        if (a.name > b.name) {
            return 1;
        }
        return 0;
    },
    byReportingDate: (a, b) => new Date(a.nextReportingDate) - new Date(b.nextReportingDate)
}

class ComapaniesInternal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            companies: null,
            sortFuncKey: "byReportingDate",
        }
    }

    componentDidMount() {
        CompanyRepo.listInternal({}, companies => this.setState({ companies }))
    }

    render() {
        const companies = this.state.companies
        return <div>
            <Header as="h2" textAlign='center'>Companies
                <Label as={Link} to={RouteC.CREATE_COMPANY_INTERNAL}>
                    <Icon name='add' />Add
                </Label>
            </Header>

            <SelectField label="Sort By: "
                valuesMap={[
                    new KeyValue("nyName", "Name"),
                    new KeyValue("byReportingDate", "Reporting Date"),
                ]}
                value={this.state.sortFuncKey}
                onChange={sortFuncKey => this.setState({ sortFuncKey })} />
            {companies === null ? <Loader fullPage={true} /> :
                <>
                    <Header as="h5" textAlign='center' className="subPageHeader">PUBLIC Companies</Header>
                    <CompaniesTable companies={companies.filter(c => c.visibility === "PUBLIC").sort(sortFuncs[this.state.sortFuncKey])}
                        companyUrlPrefix={RouteC.EDIT_COMPANY_CONFIG_INTERNAL} />
                    <Header as="h5" textAlign='center' className="subPageHeader">INTERNAL Companies</Header>
                    <CompaniesTable companies={companies.filter(c => c.visibility === "INTERNAL").sort(sortFuncs[this.state.sortFuncKey])}
                        companyUrlPrefix={RouteC.EDIT_COMPANY_CONFIG_INTERNAL} />
                </>}
        </div>
    }
}



export default ComapaniesInternal