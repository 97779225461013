import { Dropdown, Form, FormField, Grid, GridColumn, GridRow, Input, Loader, Table } from "semantic-ui-react"
import TableUpdatesRepo from "../../../../utils/repository/tableUpdatesRepo";
import { useEffect, useState } from "react";
import UserRepo from "../../../../utils/repository/userRepo";
import DatePicker from "../../../../components/form/datePicker";
import { ROLES } from "../../../../constnats/user";
import CollectionUtils from "../../../../utils/collections";
import AuthService from "../../../../service/auth";
import { ReviewType, UpdatePurpose } from "../../../../components/financialTables/configuration/constants";
import { adjustedUserGroupedMistakeKPIs, getRawUserGroupedMistakeKPIs } from "../utils/qualityUtils";
import Assert from "../../../../utils/asserts";

class ConfigProps {
    static VISUAL_CHECK_PER_CELL_W = "visualCheckPerCell"
    static CORE_DATA_CHECK_PER_CELL_W = "coreDataCheckPerCell"
    static FULL_CHECK_PER_CELL_W = "fullCheckPerCell"
    static REGULAR_UPDATE_PER_CELL_W = "regularUpdatePerCell"
    static MISTAKES_DETECTION_UPDATE_PER_CELL_W = "mistalesDetectionPerCell"
    static KPI_PER_CELL_W = "kpiPerCell"
    static IS_PER_CELL_W = "isPerCell"
    static OTHER_PER_CELL_W = "otherPerCell"
    static TABLE_TYPES = "tableTypes"

    static T_MISTAKES_FOR_R_UPDATE = "maxMistakesForReqularUpdate"
    static T_MISTAKES_FOR_F_C_REVIEW = "maxMistakesForFullCheckReview"
    static T_MISTAKES_FOR_C_D_C_REVIEW = "maxMistakesForCoreDataCheckReview"
}

const DEFAULT_CONFIG = {
    [ConfigProps.VISUAL_CHECK_PER_CELL_W]: 0.1,
    [ConfigProps.CORE_DATA_CHECK_PER_CELL_W]: 0.37,
    [ConfigProps.FULL_CHECK_PER_CELL_W]: 0.72,
    [ConfigProps.REGULAR_UPDATE_PER_CELL_W]: 1,
    [ConfigProps.MISTAKES_DETECTION_UPDATE_PER_CELL_W]: 0.9,
    [ConfigProps.KPI_PER_CELL_W]: 1.7,
    [ConfigProps.IS_PER_CELL_W]: 1,
    [ConfigProps.OTHER_PER_CELL_W]: 1,

    [ConfigProps.T_MISTAKES_FOR_R_UPDATE]: 1.31,
    [ConfigProps.T_MISTAKES_FOR_F_C_REVIEW]: 1.02,
    [ConfigProps.T_MISTAKES_FOR_C_D_C_REVIEW]: 0.64,

    [ConfigProps.TABLE_TYPES]: []
}


function getTableTypes(performanceKpis) {

    const tableTypes = new Set()
    const entities = [...performanceKpis.updates, ...performanceKpis.reviews]
    entities.map(c => c.tableType)
        .forEach(t => tableTypes.add(t))

    return Array.from(tableTypes)
}

function getWeightForTableType(config, tableType) {
    let weight
    if (tableType === "KPIs") {
        weight = config[ConfigProps.KPI_PER_CELL_W]
    } else if (tableType === "Income Statement") {
        weight = config[ConfigProps.IS_PER_CELL_W]
    } else {
        weight = config[ConfigProps.OTHER_PER_CELL_W]
    }

    return weight

}
function accumulateUpdatesPerformance(userToPerformanceKPIs, performance, config) {
    const tableTypes = config[ConfigProps.TABLE_TYPES]
    const updates = performance.updates
        .filter(u => tableTypes.includes(u.tableType))
        .filter(u => u.cellsScope !== null)
        .filter(u => userToPerformanceKPIs[u.userId] !== undefined)
        .filter(u => u.purpose !== UpdatePurpose.MISTAKES_HOLDER)

    for (const updatesPerformance of updates) {
        const tableType = updatesPerformance.tableType
        const weight = getWeightForTableType(config, tableType)

        const score = updatesPerformance.cellsScope * weight
        if (updatesPerformance.purpose === UpdatePurpose.MISTAKES_DETECTION) {
            userToPerformanceKPIs[updatesPerformance.userId].mistakesDetectionUpdates += score
        } else {
            userToPerformanceKPIs[updatesPerformance.userId].regularUpdates += score
        }
    }
}

function accumulateReviewsPerformance(userToPerformanceKPIs, performance, config) {
    const tableTypes = config[ConfigProps.TABLE_TYPES]

    const reviews = performance.reviews
        .filter(r => tableTypes.includes(r.tableType))
        .filter(u => u.cellsScope !== null)
        .filter(u => userToPerformanceKPIs[u.userId] !== undefined)

    for (const reviewsPerormance of reviews) {
        const tableType = reviewsPerormance.tableType
        const weight = getWeightForTableType(config, tableType)

        const score = reviewsPerormance.cellsScope * weight
        if (reviewsPerormance.type === ReviewType.FULL_CHECK) {
            userToPerformanceKPIs[reviewsPerormance.userId].fullReviews += score
        } else if (reviewsPerormance.type === ReviewType.CORE_DATA_CHECK) {
            userToPerformanceKPIs[reviewsPerormance.userId].coreDataReviews += score
        } else {
            userToPerformanceKPIs[reviewsPerormance.userId].visualChecks += reviewsPerormance.cellsScope
        }
    }
}

function adjustPerformanceByReviewAndUpateType(userToPerformanceKPIs, config) {
    Object.values(userToPerformanceKPIs).forEach(perf => {
        perf.mistakesDetectionUpdates = perf.mistakesDetectionUpdates * config[ConfigProps.MISTAKES_DETECTION_UPDATE_PER_CELL_W]
        perf.regularUpdates = perf.regularUpdates * config[ConfigProps.REGULAR_UPDATE_PER_CELL_W]
        perf.fullReviews = perf.fullReviews * config[ConfigProps.FULL_CHECK_PER_CELL_W]
        perf.coreDataReviews = perf.coreDataReviews * config[ConfigProps.CORE_DATA_CHECK_PER_CELL_W]
        perf.visualChecks = perf.visualChecks * config[ConfigProps.VISUAL_CHECK_PER_CELL_W]
    })
}

function caclulateTotal(userToPerformanceKPIs) {
    Object.values(userToPerformanceKPIs).forEach(perf => {
        perf.totalScore = perf.mistakesDetectionUpdates +
            perf.regularUpdates + perf.fullReviews + perf.coreDataReviews +
            perf.visualChecks
    })
}

function caclulateMistakesAdjustedTotal(userToPerformanceKPIs, mistakeAjustedKPIs, config) {
    Object.keys(userToPerformanceKPIs).forEach(userId => {
        const userPerformance = userToPerformanceKPIs[userId]
        const userMistakes = mistakeAjustedKPIs[userId]

        const mistakesDetectionUpdates = adjustForMistakes(
            userPerformance.mistakesDetectionUpdates, userMistakes.updatesScore,
            config[ConfigProps.T_MISTAKES_FOR_R_UPDATE])

        const regularUpdates = adjustForMistakes(userPerformance.regularUpdates,
            userMistakes.updatesScore, config[ConfigProps.T_MISTAKES_FOR_R_UPDATE])

        const fullReviews = adjustForMistakes(userPerformance.fullReviews,
            userMistakes.fullReviewsScore, config[ConfigProps.T_MISTAKES_FOR_F_C_REVIEW])

        const coreDataReviews = adjustForMistakes(userPerformance.coreDataReviews,
            userMistakes.coreDataReviewsScore, config[ConfigProps.T_MISTAKES_FOR_C_D_C_REVIEW])

        userPerformance.totalScoreMistakesAdjusted = mistakesDetectionUpdates +
            regularUpdates + fullReviews + coreDataReviews +
            userPerformance.visualChecks
    })
}


function adjustForMistakes(productivityScore, mistakeScore, mistakesScoreMaxTarget) {
    Assert.typeNumber(productivityScore)
    Assert.assertTrue(() => typeof mistakeScore === "number" || mistakeScore === undefined)
    mistakeScore = mistakeScore === undefined ? 0 : mistakeScore
    mistakesScoreMaxTarget = Number(mistakesScoreMaxTarget)

    const divider = (mistakeScore <= mistakesScoreMaxTarget) ? 1 : (mistakeScore / mistakesScoreMaxTarget)
    return productivityScore / divider
}

function getQuantityPerformance(performance, usersMap, config, mistakeAjustedKPIs) {
    const userToPerformanceKPIs = {}
    for (const userId of Object.keys(usersMap)) {
        userToPerformanceKPIs[userId] = {
            regularUpdates: 0,
            mistakesDetectionUpdates: 0,
            fullReviews: 0,
            coreDataReviews: 0,
            visualChecks: 0
        }
    }

    accumulateUpdatesPerformance(userToPerformanceKPIs, performance, config)
    accumulateReviewsPerformance(userToPerformanceKPIs, performance, config)
    adjustPerformanceByReviewAndUpateType(userToPerformanceKPIs, config)
    caclulateTotal(userToPerformanceKPIs)
    caclulateMistakesAdjustedTotal(userToPerformanceKPIs, mistakeAjustedKPIs, config)

    return userToPerformanceKPIs
}



function ProductivityKPIs({ usersMap, from, to }) {
    const [performance, setPerformance] = useState({ updates: [], reviews: [] })
    const [allTableTypes, setAllTableTypes] = useState([])
    const [config, setConfig] = useState(DEFAULT_CONFIG)

    useEffect(() => {
        TableUpdatesRepo.performanceKpisV2({
            from, to,
            includeDataWithUnknownMistakes: true
        }, (performanceKpis) => {

            const tableTypesArr = getTableTypes(performanceKpis)
            setAllTableTypes(tableTypesArr)
            setConfig({ ...config, tableTypes: tableTypesArr })
            setPerformance(performanceKpis)
        })

    }, [from, to]);


    const userToMistakesKPIs = getRawUserGroupedMistakeKPIs(Object.keys(usersMap), performance, config[ConfigProps.TABLE_TYPES])
    const mistakeAjustedKPIs = CollectionUtils.arrToMap(adjustedUserGroupedMistakeKPIs(userToMistakesKPIs), userKips => userKips.userId)
    const userToPerformanceKPIs = getQuantityPerformance(performance, usersMap, config, mistakeAjustedKPIs)

    return <>
        <br /><br />
        <QuantityPerformanceConfig
            config={config}
            tableTypes={allTableTypes}
            onChange={setConfig} />
        <Table striped selectable celled>
            <Table.Header>
                <Table.Row >
                    <Table.HeaderCell rowSpan='2'>
                        User
                    </Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2'>
                        Updates Score
                    </Table.HeaderCell>
                    <Table.HeaderCell colSpan='3'>
                        Reviews Score
                    </Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2'>
                        Total Score
                    </Table.HeaderCell>
                    <Table.HeaderCell rowSpan='2'>
                        Total Score M. Adjusted
                    </Table.HeaderCell>
                </Table.Row>
                <Table.Row >
                    <Table.HeaderCell>Full Checks</Table.HeaderCell>
                    <Table.HeaderCell>Core Data Checks</Table.HeaderCell>
                    <Table.HeaderCell>Visual Checks</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                {Object.keys(userToPerformanceKPIs)
                    .map(userId => {
                        const userKnowledge = userToPerformanceKPIs[userId]
                        return { userId, ...userKnowledge }
                    })
                    .sort((a, b) => b.totalScore - a.totalScore)
                    .map(({
                        userId, regularUpdates, mistakesDetectionUpdates, fullReviews, coreDataReviews, visualChecks,
                        totalScore, totalScoreMistakesAdjusted }) =>
                        <Table.Row key={userId}>
                            <Table.Cell>
                                {usersMap[userId]?.fullName || userId}
                            </Table.Cell>
                            <Table.Cell>
                                <NumberCell number={regularUpdates + mistakesDetectionUpdates} />
                            </Table.Cell>
                            <Table.Cell >
                                <NumberCell number={fullReviews} />
                            </Table.Cell>
                            <Table.Cell >
                                <NumberCell number={coreDataReviews} />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberCell number={visualChecks} />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberCell number={totalScore} />
                            </Table.Cell>
                            <Table.Cell>
                                <NumberCell number={totalScoreMistakesAdjusted} />
                            </Table.Cell>
                        </Table.Row>
                    )
                }
            </Table.Body>
        </Table>
    </>
}




function QuantityPerformanceConfig({ config, tableTypes,
    onChange }) {

    function onPropChange(prop, newVal) {
        onChange({ ...config, [prop]: newVal })
    }

    function getWeightField(label, prop) {
        return <WeightInput
            label={label}
            onChange={v => onPropChange(prop, v)}
            value={config[prop]}
        />
    }

    return <>
        <Form>

            <br />

            <Grid columns={5} divided>
                <GridRow>

                    <GridColumn>
                        Weight per update type
                        {getWeightField('Regular Update', ConfigProps.REGULAR_UPDATE_PER_CELL_W)}
                        {getWeightField('Mistake Detection Update', ConfigProps.MISTAKES_DETECTION_UPDATE_PER_CELL_W)}
                    </GridColumn>
                    <GridColumn>
                        Weight per review type
                        {getWeightField('Full Check', ConfigProps.FULL_CHECK_PER_CELL_W)}
                        {getWeightField('Core Data Check', ConfigProps.CORE_DATA_CHECK_PER_CELL_W)}
                        {getWeightField('Visual Check', ConfigProps.VISUAL_CHECK_PER_CELL_W)}
                    </GridColumn>
                    <GridColumn>
                        Weight per table type
                        {getWeightField('IS', ConfigProps.IS_PER_CELL_W)}
                        {getWeightField('KPIs', ConfigProps.KPI_PER_CELL_W)}
                        {getWeightField('Other', ConfigProps.OTHER_PER_CELL_W)}
                    </GridColumn>
                    <GridColumn>
                        Target max mistakes per table type
                        {getWeightField('Updates', ConfigProps.T_MISTAKES_FOR_R_UPDATE)}
                        {getWeightField('Full Check Review', ConfigProps.T_MISTAKES_FOR_F_C_REVIEW)}
                        {getWeightField('Core Data Check Review', ConfigProps.T_MISTAKES_FOR_C_D_C_REVIEW)}

                    </GridColumn>
                    <GridColumn>
                        Table types to include
                        <Form.Field>
                            <label>Table Types </label>
                            <Dropdown
                                onChange={(a, b) => onPropChange("tableTypes", b.value)}
                                value={config.tableTypes}
                                placeholder='Table Types'
                                options={tableTypes.map(t => {
                                    return { key: t, text: t, value: t }
                                })}
                                multiple selection search />
                        </Form.Field >
                    </GridColumn>
                </GridRow>
            </Grid>
        </Form>
    </>
}

function NumberCell({ number }) {
    return Number(number).toFixed(0)
}

function WeightInput({ label, onChange, value }) {
    return <FormField
        control={Input}
        label={label}
        onChange={e => onChange(e.target.value)}
        value={value}
    />
}

function ProductivityMetrix() {
    const [usersMap, setUsersMap] = useState(null)
    const [from, setFrom] = useState(() => new Date(new Date().setDate(new Date().getDate() - 14)))
    const [to, setTo] = useState(() => new Date())

    const fromTM = new Date(from.getTime()).setUTCHours(0, 0, 0, 0)
    const toTM = new Date(to.getTime()).setUTCHours(23, 59, 0, 0)

    useEffect(() => {
        UserRepo.list({ userRoles: [ROLES.EMPLOYEE], includeDisabled: false },
            users => setUsersMap(CollectionUtils.arrToMap(users, u => u.id)))
    }, []);
    const currentUserId = AuthService.getUserId()

    if (!AuthService.hasGlobalRole(ROLES.ADMIN) && currentUserId !== 2495 && currentUserId !== 2491) {
        return <>Not enoug privileges!</>
    }

    return usersMap === null ? <Loader /> : <>
        <DatePicker
            label="From:" date={new Date(from)}
            onChange={d => setFrom(d)} />
        <DatePicker className="smallField"
            label="To:" date={new Date(to)}
            onChange={d => setTo(d)} /> NOTE: Dates before 13 Mar. 2024 are not supported
        <ProductivityKPIs {...{ usersMap, from: fromTM, to: toTM }} />
    </>
}

export default ProductivityMetrix


