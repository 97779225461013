import React from 'react'
import { Dimmer, Loader as L, Segment, Container } from 'semantic-ui-react'

function Loader({ msg }) {
    msg = msg === undefined ? "Loading" : msg
    return <Container>
        <Segment className='loaderContainer'>
            <Dimmer active inverted>
                <L size='large'>{msg}</L>
            </Dimmer>
        </Segment>
    </Container>
}

export default Loader 