import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "semantic-ui-react";
import CategoriesRepo from "../../../../utils/repository/categoriesRepo";
import { DataCalcType } from "../../company/components/financialTables/schema/descriptors/models/descriptorModel";
import { SelectField } from "../../../../components/form/fields";
import KeyValue from "../../../../structures/keyValePair";
import FiledsValidator from "../../../../utils/validation/fieldsValidator";
import Alert from "../../../../utils/alert";
import Loader from "../../../../components/loader";

const INPUT_FIELDS = {
    name: {
        label: "Name",
        type: "text",
    },
    p2pCalcType: {
        label: "Data Calc. Type",
        type: "select",
        options: DataCalcType.getAllTypes().map(KeyValue.createSame),
        default: DataCalcType.NA
    },
    scalable: {
        label: "Scalable",
        type: "checkbox",
        default: true,
    },
};

const maxLength = 100
const minLength = 1
const FIELDS_VALIDATOR = new FiledsValidator(["name"], {
    name: {
        validate: (val) => val.length < maxLength && val.length > minLength,
        msg: "The category name must be shorter than " + maxLength + " symbols and longer than " + minLength,
    },
});

export default function CategoryModal({
    id = null,
    onClose,
    onCategoryChange,
}) {
    const [category, setCategory] = useState(null);
    const [errorValidation, setErrorValidation] = useState({});

    function validate() {
        const validationRes = FIELDS_VALIDATOR.validate(category);
        if (validationRes.newErrorProps) {
            setErrorValidation(validationRes.newErrorProps);
        }
        return validationRes.valid;
    }

    function onCategorySaved() {
        Alert.success("Saved");
        onCategoryChange();
        onClose();
    }

    function handleSubmit() {
        if (validate()) {
            if (id) {
                CategoriesRepo.update(category, onCategorySaved);
            } else {
                CategoriesRepo.save(category, onCategorySaved);
            }
        }
    }

    useEffect(() => {
        if (id) {
            CategoriesRepo.getOne(id, setCategory);
        } else {
            const newCategory = {};
            Object.keys(INPUT_FIELDS).forEach((key) => {
                newCategory[key] =
                    INPUT_FIELDS[key].default !== undefined
                        ? INPUT_FIELDS[key].default
                        : "";
            });
            setCategory(newCategory);
        }
    }, []);

    function _input(fieldId) {
        let input;
        const field = INPUT_FIELDS[fieldId]
        const type = field.type
        const label = field.label
        const error = errorValidation[FIELDS_VALIDATOR.getErrorPropName(fieldId)];
        if (type === "select") {
            input = (
                <SelectField
                    key={fieldId}
                    error={error}
                    value={category[fieldId]}
                    valuesMap={field.options}
                    number={false}
                    label={label}
                    className="thinField"
                    onChange={(v) => {
                        setCategory({ ...category, [fieldId]: v });
                    }}
                />
            );
        } else if (type === "checkbox") {
            input = (
                <Form.Checkbox
                    key={fieldId}
                    error={error}
                    label={label}
                    checked={category[fieldId] === true}
                    onChange={(e, d) => {
                        setCategory({ ...category, [fieldId]: d.checked });
                    }}
                />
            );
        } else {
            input = (
                <Form.Input
                    key={fieldId}
                    error={error}
                    input={type}
                    label={label}
                    onChange={(v) => setCategory({ ...category, [fieldId]: v.target.value })}
                    value={category[fieldId]}
                />
            );
        }

        return input;
    }

    return (
        <Modal size="fullscreen" open={true}>
            <Modal.Header>{id ? "Edit Category" : "Add Category"}</Modal.Header>
            {category ? (
                <Modal.Content>
                    <Form className="categoryForm">
                        {Object.keys(INPUT_FIELDS).map(_input)}
                    </Form>
                </Modal.Content>
            ) : (
                <Loader fullPage={true} />
            )}
            <Modal.Actions>
                <Button
                    size="mini"
                    className="ui mini primary button"
                    onClick={handleSubmit}
                >
                    Submit
                </Button>
                <Button size="mini" onClick={onClose}>
                    Close
                </Button>
            </Modal.Actions>
        </Modal>
    );
}
