import React from 'react'
import Column from './column'
import HeaderColumn from './headerColumn'
import ReportsRepo from '../../../../../../utils/repository/repoertsRepo'
import { DescriptorProps } from '../schema/descriptors/models/descriptorModel';
import { ReportFillingType } from '../../../../../../constnats/reportConstants';
import CopyDataModal from './copyReportDataModal'
import ReportUtils from '../../../../../../utils/reportUtils';
import DescriptorPropsColumns from './descriptorPropsColumns';
import { ConfigProps } from '../../../../../../components/financialTables/configuration/constants';

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      reports: this.props.reports.slice(),
      modal: <></>,
      highlightedRows: [],
    }

    this.columnWidth = "auto"
    this.dirtyReports = new Set()
    this.refs = []

    this.addReport = this.addReport.bind(this)
    this.hasNewNotSavedReport = false
  }

  componentWillReceiveProps(nextProps) {
    const newReports = nextProps.reports.slice()
    const theNewReport = this.getTheNewReport()
    if (theNewReport !== null && this.hasNewNotSavedReport) {
      newReports.unshift(theNewReport)
    }

    this.setState({ reports: newReports })
    const repIds = nextProps.reports.map(r => r.id)
    for (const id of this.dirtyReports) {
      if (!repIds.includes(id)) {
        this.dirtyReports.delete(id)
      }
    }
    this.updateParentDirtyState()
  }

  isNew(report) {
    return report.id === "new"
  }

  getTheNewReport() {
    const firstReport = this.state.reports[0]
    return firstReport && this.isNew(firstReport) ? firstReport : null
  }

  addReport() {
    if (this.getTheNewReport() !== null) {
      return
    }

    const report = Object.assign(
      ReportUtils.createEmptyReport("new"), {
      [DescriptorProps.TYPE]: ReportFillingType.ORIGINAL_FILLING
    })

    const copy = this.state.reports.slice()
    copy.unshift(report)
    this.hasNewNotSavedReport = true
    this.setState({ reports: copy })
  }

  componentDidMount() {
    let width = 90;
    for (const ref of this.refs) {
      if (ref.current.offsetWidth > width) {
        width = ref.current.offsetWidth
      }
    }

    this.columnWidth = width + 'px'

    for (const ref of this.refs) {
      ref.current.firstChild.style.width = this.columnWidth;
    }
  }

  onReportDirtyChange(dirty, id) {
    if (dirty) {
      this.dirtyReports.add(id)
    } else {
      this.dirtyReports.delete(id)
    }

    this.updateParentDirtyState()
  }

  updateParentDirtyState() {
    if (this.dirtyReports.size > 0) {
      this.props.setDirty(true)
    } else {
      this.props.setDirty(false)
    }
  }

  save(report, onSuccess) {

    const finalize = () => {
      onSuccess()
      this.props.onChange()
    }

    if (this.isNew(report)) {
      delete report.id
      report.schemaId = this.props.schemaId
      ReportsRepo.save(report, res => {
        this.hasNewNotSavedReport = false;
        finalize()
      })
    } else {
      ReportsRepo.update(report, res => finalize())
    }
  }

  delete(id, onSuccess) {
    if (id === "new") {
      this.hasNewNotSavedReport = false
      this.props.onChange()
    } else {
      ReportsRepo.delete(id, res => {
        this.props.onChange()
        onSuccess()
      })
    }
  }


  openCopyDataModal(report) {
    this.setState({
      modal: <CopyDataModal
        descriptors={this.props.descriptors}
        schemaId={this.props.schemaId}
        srcReport={report}
        copyTargets={this.props.getCopyTargets(report)}
        closeModal={hasChanges => {
          this.setState({ modal: null })
          if (hasChanges) {
            this.props.onChange()
          }
        }}
      />
    })
  }

  columns() {
    const state = this.state
    this.refs = state.reports.map(e => React.createRef());
    return state.reports.map((r, i) =>
      <div key={r.id + "_column"} className='inline-block' ref={this.refs[i]} >
        <Column
          highlightedRows={state.highlightedRows}
          isNew={this.isNew(r)}
          columnStyle={{ minWidth: "74px", width: this.columnWidth }}
          save={(report, onSuccess) => this.save(report, onSuccess)}
          delete={(reportId, onSuccess) => this.delete(reportId, onSuccess)}
          descriptors={this.props.descriptors}
          schemaId={this.props.schemaId}
          schemaName={this.props.schemaName}
          report={r}
          // getCalculatedReport={this.props.getCalculatedReport}
          openCopyDataModal={report => this.openCopyDataModal(report)}
          setDirty={dirty => this.onReportDirtyChange(dirty, r.id)}
          readOnly={this.props.readOnly}
          companyId={this.props.companyId}
          advancedTableCfg={this.props.advancedTableCfg}
          />
      </div>
    )
  }

  render() {
    console.log("RENDER TABLE")
    return <>
      {this.state.modal}
      <div className="reportTable" textalign='center'>
        <HeaderColumn position="absolute"
          readOnlyMode={this.props.readOnly}
          addReport={this.addReport}
          descriptors={this.props.descriptors}
          highlightedRows={this.state.highlightedRows}
          setHighlightedRows={highlightedRows => this.setState({ highlightedRows })}
          advancedTableCfg={this.props.advancedTableCfg}
        />
        <div className={`dataColumns headerWidth_${this.props.advancedTableCfg[ConfigProps.HEADER_COLUMN_WIDTH]}`}>
          {this.props.showFieldsSettings && <DescriptorPropsColumns
            readOnlyMode={this.props.readOnly}
            addReport={this.addReport}
            highlightedRows={this.state.highlightedRows}
            setHighlightedRows={highlightedRows => this.setState({ highlightedRows })}
            flatDescriptors={this.props.flatDescriptors} />}
          {this.columns()}
        </div>
      </div>
    </>
  }
}

export default Table