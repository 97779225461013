import React, { useEffect, useState } from "react";
import { Header, Label, Icon, Table } from "semantic-ui-react";
import { serverToLocalDate } from "../../../utils/dateTimeUtils";
import CategoriesRepo from "../../../utils/repository/categoriesRepo";
import CategoryModal from "./components/categoryModal";
import ConfirmationModal from "../../../components/confirmationModal";
import CategoryOptions from "./components/categoryOptions";
import Loader from "../../../components/loader";
import { CONFIRM_DELETE_CATEGORY } from "../../../messages";

export default function Categories() {
    const [categories, setCategories] = useState([]);
    const [modal, setModal] = useState(null);
    const [loader, setLoader] = useState(true);

    function openCreateUpdateCategoryModal(id) {
        setModal(
            <CategoryModal
                id={id}
                onCategoryChange={loadCategories}
                onClose={() => {
                    setModal(null);
                }}
            />
        );
    }

    function deleteCategory(id) {
        CategoriesRepo.delete(id, loadCategories);
    }

    function openConfirmModal(id) {
        setModal(
            <ConfirmationModal
                msg={CONFIRM_DELETE_CATEGORY}
                onConfirm={() => {
                    deleteCategory(id);
                    setModal(null);
                }}
                onCancel={() => setModal(null)}
            />
        );
    }

    function loadCategories() {
        CategoriesRepo.getAll((r) => {
            setCategories(r);
            setLoader(false);
        });
    }

    useEffect(() => {
        loadCategories();
    }, []);

    return (
        <div className="categories">
            {modal}
            <Header as="h2" textAlign="center">
                Categories
                <Label
                    onClick={() => openCreateUpdateCategoryModal()}
                    className="cursorPointer">
                    <Icon name="add" />
                    Add
                </Label>
            </Header>
            {loader ? (
                <Loader fullPage={true} />
            ) : (
                <Table striped>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.HeaderCell>Name</Table.HeaderCell>
                            <Table.HeaderCell>Calc Type</Table.HeaderCell>
                            <Table.HeaderCell>Scalable</Table.HeaderCell>
                            <Table.HeaderCell>Created On</Table.HeaderCell>
                            <Table.HeaderCell>Updated On</Table.HeaderCell>
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {categories.length !== 0 &&
                            categories.map((c) => (
                                <Table.Row key={c.id}>
                                    <Table.Cell collapsing className="noPadding">
                                        <CategoryOptions
                                            id={c.id}
                                            openModal={openCreateUpdateCategoryModal}
                                            deleteCategory={openConfirmModal}
                                        />
                                    </Table.Cell>
                                    <Table.Cell>{c.id}</Table.Cell>
                                    <Table.Cell>{c.name}</Table.Cell>
                                    <Table.Cell>{c.p2pCalcType}</Table.Cell>
                                    <Table.Cell>{c.scalable ? "YES" : "NO"}</Table.Cell>
                                    <Table.Cell>{serverToLocalDate(c.createdOn)}</Table.Cell>
                                    <Table.Cell>{serverToLocalDate(c.updatedOn)}</Table.Cell>
                                </Table.Row>
                            ))}
                    </Table.Body>
                </Table>
            )}
        </div>
    );
}
