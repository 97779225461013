import React from 'react';
import Table from './table/table'
import { DUPLICATED_REPORTS_MSG, ReportsUtils } from '../../../../../utils/reportsUtils'
import ReportsRepo from '../../../../../utils/repository/repoertsRepo';
import Loader from '../../../../../components/loader';
import { PasteMode } from './table/pasteMode';
import { Descriptor, DescriptorProps, DescriptorType } from './schema/descriptors/models/descriptorModel';
import { Button, Checkbox, Form, Grid, Segment } from 'semantic-ui-react';
import FilterAndSortCfg from '../../../../../components/financialTables/configuration/filterAndSortConfig';
import { applyFilterCfg } from '../../../../../components/financialTables/configuration/applyUtill';
import UserPreferences from '../../../../../service/userPreferences';
import Utils from '../../../../../utils/descriptor/descriptorUtils';
import BatchUpdateModal from './table/batchUpdateModal';
import SyncWithResurcesModal from './syncWithResourcesModal'
import { getAvalableTargetReports } from './table/utils/copyReportDataUtils'
import { ConfigProps, PropValues } from '../../../../../components/financialTables/configuration/constants';
import Alert from '../../../../../utils/alert';
import { serverToLocalDate } from '../../../../../utils/dateTimeUtils';
import { ReportField } from '../../../../../constnats/reportConstants';
import UserRepo from '../../../../../utils/repository/userRepo';
import { ROLES } from '../../../../../constnats/user';
import TableAndP2pConfig from '../../../../../components/financialTables/configuration/tableAndP2pConfig';
import TableChart from '../../../../company/components/fiancialTable/tableChart';
import AdvancedSettingsToggle from '../../../../../components/buttons/advancedSettingsToggle';
import CloneUtils from '../../../../../utils/cloneUtils';
import hash from "object-hash"

function systemFieldDescriptors() {
    const parentDescriptor = new Descriptor("system-fields-separator-parent",
        "System Fields", null, false, {}, false,
        DescriptorType.HEADLINE, false)

    const systemFieldDescriptors = [
        new Descriptor("system-fields-separator", "",
            null, false, { backgroundColor: "#c2c2c2" }, false, DescriptorType.HEADLINE, false),
        parentDescriptor
    ]

    const subFields = [
        new Descriptor("id", "ID",
            null, false, {}, false, DescriptorType.ANY, false),
        new Descriptor(ReportField.OWNER_NAME, "Owner",
            null, false, {}, false, DescriptorType.ANY, false),
        new Descriptor(ReportField.FULL_CHECKS_COUNT, "Full Checks",
            null, false, {}, false, DescriptorType.ANY, false),
        new Descriptor(ReportField.VISUAL_CHECKS_COUNT, "Visual Checks",
            null, false, {}, false, DescriptorType.ANY, false),
        new Descriptor(ReportField.CREATED_ON_DATE_ONLY, "Created On",
            null, false, {}, false, DescriptorType.ANY, false),
        new Descriptor(ReportField.UPDATED_ON_DATE_ONLY, "Updated On",
            null, false, {}, false, DescriptorType.ANY, false)
    ]

    parentDescriptor.subFields = subFields

    return systemFieldDescriptors
}


class EmployeesProvider {

    static employees = null

    static getAll() {
        return new Promise(resolve => {
            if (EmployeesProvider.employees === null) {
                UserRepo.list({ userRoles: [ROLES.EMPLOYEE], includeDisabled: true },
                    users => {
                        EmployeesProvider.employees = users
                        resolve(users)

                    })
            } else {
                return resolve(EmployeesProvider.employees);
            }
        })
    }
}

async function getNormalizedReports(reports) {
    const reportsCopy = JSON.parse(JSON.stringify(reports))
    const users = await EmployeesProvider.getAll()

    const nonNullFields = [
        ReportField.OWNER_ID,
        ReportField.FULL_CHECKS_COUNT,
        ReportField.VISUAL_CHECKS_COUNT
    ]
    for (const report of reportsCopy) {
        for (const field of nonNullFields) {
            if (report[field] === null) {
                report[field] = ""
            }
        }

        report[ReportField.CREATED_ON_DATE_ONLY] = serverToLocalDate(report[ReportField.CREATED_ON])
        report[ReportField.UPDATED_ON_DATE_ONLY] = serverToLocalDate(report[ReportField.UPDATED_ON])

        if (report[ReportField.OWNER_ID] !== "") {
            report[ReportField.OWNER_NAME] = users.find(u => u.id === report[ReportField.OWNER_ID])?.fullName
        }

    }

    return reportsCopy
}

class Reports extends React.Component {

    constructor(props) {
        super(props)
        this.reloadReports = this.reloadReports.bind(this)

        this.descriptors = JSON.parse(JSON.stringify(this.props.schema.descriptors))

        Utils.forEach(this.descriptors, descriptor => {
            if (descriptor[DescriptorProps.IMPORTANCE] === 10) {
                descriptor[DescriptorProps.STYLE] = {
                    ...descriptor[DescriptorProps.STYLE],
                    backgroundColor: "rgb(194, 194, 194)"
                }
            }
        })

        if (!this.props.readOnly) {
            this.descriptors.unshift(
                new Descriptor(DescriptorProps.INPUT_SCALE, "Primary Input Scale",
                    null, false, {}, false, DescriptorType.ANY, false))

        } else {
            this.descriptors.push(...systemFieldDescriptors())
        }


        this.flatDescriptors = Utils.flatDescriptors(this.descriptors)
        this.dynamicFlatDescriptors = this.flatDescriptors
            .filter(d => d[DescriptorProps.DYNAMIC] === true)
        this.reportsUtil = new ReportsUtils(this.dynamicFlatDescriptors)


        this.state = {
            reports: [],
            filterCfg: UserPreferences.get(UserPreferences.FILTER_CFG_PROP),
            /**
             * in the client view part of the app this is named table config but
             * both cases(here and in client view) serve the same purpose
             */
            advancedTableCfg: UserPreferences.get(UserPreferences.TABLE_CFG_PROP),
            loading: true,
            showFieldsSettings: false,
            syncReportsModal: null
        }

        this.calculatedReports = []
    }

    componentDidMount() {
        this.reloadReports()
    }
    /**
     * @param {Object} [additionalState] Optional An object that contains pieces of state
     * example:
     * { advancedTableCfg: {contains the new advanced table config settings} }
     */
    reloadReports(additionalState) {
        ReportsRepo.listBySchema(this.props.schema.id, async reports => {
            reports = await getNormalizedReports(reports)
            this.calculatedReports = this.reportsUtil.getCalculatedReports(reports,
                (a, b) => {
                    Alert.warn(DUPLICATED_REPORTS_MSG);
                    return a
                })
            this.setState({
                reports: reports,
                loading: false,
                ...additionalState
            })
        })
    }

    syncReportsWithModel() {
        const closeModal = () => this.setState({ syncReportsModal: null })
        this.setState({
            syncReportsModal: <BatchUpdateModal
                descriptors={this.props.schema.descriptors}
                reports={this.state.reports}
                closeModal={closeModal}
                onSaved={() => {
                    this.reloadReports()
                    closeModal()
                }} />
        })
    }

    syncReportsWithResourcesModel() {

        const closeModal = () => this.setState({ syncReportsModal: null })
        this.setState({
            syncReportsModal: <SyncWithResurcesModal
                companyId={this.props.companyId}
                schemaId={this.props.schema.id}
                closeModal={closeModal}
                onCreate={() => {
                    this.reloadReports()
                    closeModal()
                }} />
        })
    }

    toggleAdvancedSettings = () => {
        this.setState({ filterCfg: { ...this.state.filterCfg, [ConfigProps.SHOW_ADVANCED_SETTINGS]: !this.state.filterCfg[ConfigProps.SHOW_ADVANCED_SETTINGS] } })
    }

    /**
     * 
     * @param {Object} settings Required Object containing the advancedTableCfg props and values
     */
    onAdvancedTableCfgChange = settings => {
        UserPreferences.save("advancedTableCfg", settings)
        this.reloadReports({ advancedTableCfg: settings });
    }

    content() {
        const filterCfg = this.state.filterCfg
        const schema = this.props.schema
        const readOnly = this.props.readOnly
        const reports = ReportsUtils.sort(applyFilterCfg(this.state.reports.concat(this.calculatedReports),
            filterCfg, this.state.advancedTableCfg, this.dynamicFlatDescriptors),
            filterCfg[ConfigProps.REPORTS_ORDER] === PropValues.DESC).map(report => {
                report.hash = hash(report)
                return report
            })
        return <>
            <Form size="mini">
                <FilterAndSortCfg config={filterCfg}
                    onChange={filterCfg => {
                        this.setState({ filterCfg })
                        UserPreferences.save(UserPreferences.FILTER_CFG_PROP, filterCfg)
                    }} granularPeriodsFiltering={this.state.advancedTableCfg[ConfigProps.GRANULAR_PERIODS_FILTERING]} />
                {filterCfg[ConfigProps.SHOW_ADVANCED_SETTINGS] && <TableAndP2pConfig tableConfig={this.state.advancedTableCfg}
                    onTableConfigChange={advancedTableCfg => this.onAdvancedTableCfgChange(advancedTableCfg)}
                />}
                <Segment className='fillerOnlySettings'>
                    <Grid divided>
                        <Grid.Row>
                            <Grid.Column width={4}>
                                <Checkbox className='showDescriptorSettingsCheckbox'
                                    checked={this.state.showFieldsSettings}
                                    label="Show Fields Settings"
                                    onChange={(e, { checked }) =>
                                        this.setState({ showFieldsSettings: checked })
                                    } />
                            </Grid.Column>
                            <Grid.Column width={4}>
                                <PasteMode />
                            </Grid.Column>
                            <Grid.Column  width={4} className='ml-auto no-borders'>
                                <Segment className='table-actions-container-wrapper filler-view-actions-container' basic size='mini'>
                                    <AdvancedSettingsToggle showAdvancedSettings={filterCfg[ConfigProps.SHOW_ADVANCED_SETTINGS]} toggleAdvancedSettings={this.toggleAdvancedSettings} />
                                </Segment>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Segment>
            </Form>
            {this.state.syncReportsModal}
            <Table reports={reports}
                descriptors={this.descriptors}
                schemaId={schema.id}
                schemaName={schema.name}
                onChange={this.reloadReports}
                getCopyTargets={srcReport => getAvalableTargetReports(srcReport, this.state.reports)}
                setDirty={this.props.setDirty}
                //TODO backend protection
                readOnly={readOnly}
                companyId={this.props.companyId}
                showFieldsSettings={this.state.showFieldsSettings}
                flatDescriptors={this.flatDescriptors}
                advancedTableCfg={this.state.advancedTableCfg} />
            {this.state.advancedTableCfg[ConfigProps.SHOW_CHARTS] && <Segment>
                <TableChart {...{
                    reports, dynamicFlatDescriptors: this.dynamicFlatDescriptors
                }} />
            </Segment>}
            {!readOnly && <div className='reports-table-bottom-ops'>
                <Button size='mini'
                    onClick={() => this.syncReportsWithModel()}>Sync With Model
                </Button >
                <Button size='mini'
                    onClick={() => this.syncReportsWithResourcesModel()}>Sync With Resources
                </Button >
            </div >}
            <div className='currencyInfo'>Currency: <b>{this.props.currency}</b></div>

        </>
    }

    render() {
        return this.state.loading === true ? <Loader /> : this.content()
    }
}


export default Reports