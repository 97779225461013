import React, { useState } from 'react'
import { Form, Icon, Modal, Button, TextArea, Popup, Divider, Loader } from 'semantic-ui-react'
import Utils from '../../../../../../utils/utils'
import { DescriptorProps, DescriptorType } from '../schema/descriptors/models/descriptorModel'
import { VALID, INVALID } from "../../../../../../constnats/generalConstants"
import { PasteModeModel } from './pasteMode'
import Config from '../../../../../../constnats/config'
import ScaleUtil from '../../../../../../utils/scaleUtil'
import { MetadataField, SrcFieldValue } from '../../../../../../constnats/reportConstants'
import ResourceRepo from '../../../../../../utils/repository/resourceRepo'
import { ResourceProps, sortResourceInfos, toResourceProps } from '../../../../../../utils/reportResourceUtil'
import ObjCheck from '../../../../../../utils/objCheck'
import Assert from '../../../../../../utils/asserts'
import { default as DescriptorUtils } from '../../../../../../utils/descriptor/descriptorUtils'

import EditResourceModalV2 from '../../resources/editResourceModalV2'
import { labelsSimilarityCheck, normalizeLabel } from '../../../../../../utils/labels'
import { REGEX_EXCLUDE_CURRENCY_SIGNS } from '../../../../../../constnats/regexes'

function normalizeNumberVal(newVal, paste) {
    let res = newVal.replaceAll(" ", "")
    const regexMatches = res.match(REGEX_EXCLUDE_CURRENCY_SIGNS)
    if(regexMatches) {
        Assert.trueVal(regexMatches.length === 1,"normalizeNumberVal > received more than one regex match")
        res = regexMatches.join("")
    }

    if (res.startsWith("(")) {
        res = res.replace("(", "-").replace(")", "")
    } else if (res.startsWith("[")) {
        res = res.replace("[", "-").replace("]", "")
    } else if (res.startsWith("–")) {
        res = res.replace("–", "-")
    } else if (res.startsWith("−")) {
        res = res.replace("−", "-")
    }

    if (paste && PasteModeModel.get() === PasteModeModel.US_MODE) {
        res = res.replaceAll(",", "").replace(".", ",")
    } else {
        res = res.replaceAll(".", "")
    }

    if (paste) {
        const num = Number(res.replaceAll(",", "."))
        if (!isNaN(num) && typeof num === 'number') {
            res = num.toString().replaceAll(".", ",")
        }
    }

    return res
}

function normalizePercentVal(newVal, paste) {
    let res = newVal.trim().replaceAll(" ", "")

    let hasPercent = res.includes("%")
    res = res.replace("%", "")
    res = normalizeNumberVal(res, paste)

    if (hasPercent) {
        res = res + "%"
    }

    if (paste && !res.endsWith("%")) {
        res = res + "%"
    }

    return res
}

function EditMetadataFormWrapper(props) {
    const [showInfoField, setShowInfoField] = useState(false);

    let color = "blue"
    let customConfigs = 0
    if (props.allowWarns) {
        if (ObjCheck.isNullUndefinedOrEmpty(props[MetadataField.REPORTED_AS_LABEL]) &&
            !ObjCheck.isNullUndefinedEmptyOrDash(props.data)) {
            color = "red"
        } else {
            if (props[MetadataField.MANUALLY_CALCED] === true) {
                customConfigs++
                color = "black"
            }
            if (props[MetadataField.CALCS_DISABLED] === true) {
                customConfigs++
                color = "yellow"
            }
            if (!ObjCheck.isNullUndefinedOrEmpty(props[MetadataField.NOTES])) {
                customConfigs++
                if (customConfigs === 1) {
                    color = "green"
                }
            }
        }
    }

    let iconName = customConfigs > 1 ? "th" : "ellipsis vertical"

    return <>
        <Icon className='cellInfoIcon'
            onClick={() => setShowInfoField(true)}
            color={color}
            size='small' name={iconName} />
        {showInfoField ? <EditMetadataForm {...props}
            requestClose={() => setShowInfoField(false)}
            requestSave={metadata => props.onSave(metadata)} /> : <></>}
    </>
}


function EditMetadataForm(props) {
    const [notes, setNotes] = useState(props[MetadataField.NOTES]);
    const [manuallyCalculated, setManuallyCalculated] = useState(props[MetadataField.MANUALLY_CALCED]);
    const [calcsDisabled, setCalcsDisabled] = useState(props[MetadataField.CALCS_DISABLED]);

    const fieldName = props.fieldName
    const requestClose = props.requestClose
    const requestSave = props.requestSave

    // Do not use Modal "open" for performance reasons
    return <Modal size="small" open={true}>
        <Modal.Header>{fieldName}</Modal.Header>
        <Modal.Content>
            <Form>
                <b>Notes</b>
                <TextArea placeholder='Notes'
                    value={notes}
                    onChange={v => setNotes(v.target.value)} />
                <br />
                <br />
                <Form.Checkbox label=' Calculated By 3D StockPicker (Manually Calculated)'
                    checked={manuallyCalculated}
                    onChange={(e, data) => setManuallyCalculated(data.checked)} />
                <Form.Checkbox label='Disable Automatic Calculations'
                    checked={calcsDisabled === true}
                    onChange={(e, data) => setCalcsDisabled(data.checked)} />
            </Form>
        </Modal.Content>
        <Modal.Actions>
            <Button size='mini' positive onClick={() => {
                requestClose()
                requestSave({
                    [MetadataField.NOTES]: notes,
                    [MetadataField.MANUALLY_CALCED]: manuallyCalculated,
                    [MetadataField.CALCS_DISABLED]: calcsDisabled
                })
            }}>
                Apply Changes
            </Button>
            <Button size='mini' negative onClick={() => requestClose()}>
                Cancel
            </Button>
        </Modal.Actions>
    </Modal>
}

/**
 * @param {string} fieldSrc Required
 * @param {string} reportedAsLabel Required
 * @param {string} notes Required
 * @param {boolean} manuallyCalculated Required
 * @param {string} p2pCalc Required
 * @param {string} [dataDiffDetection] Optional
 * @returns {string} Returns one of the following colors green blue gray yellow or an empty string if there is no match
 */
function getAdditionalInfoIconColor(fieldSrc, reportedAsLabel, notes, manuallyCalculated, p2pCalc, dataDiffDetection) {
    let color = "";
    if (dataDiffDetection) {
        color = 'red'
    } else if (p2pCalc) {
        color = 'yellow'
    } else if (manuallyCalculated) {
        color = 'grey'
    } else if (reportedAsLabel || notes) {
        color = 'blue'
    } else if (fieldSrc) {
        color = "green"
    } else {
        Assert.fail(`Did not match case for color. Passed in fields fieldSrc${fieldSrc},reportedAsLabel${reportedAsLabel},notes${notes},manuallyCalculated${manuallyCalculated},p2pCalc:${p2pCalc}`)
    }

    return color
}

function FieldInfoModal({ filedName, reportedAsLabel, correction, viewScale,
    notes, fieldSrc, manuallyCalculated, p2pCalc, calcsDisabled, dataDiffExp, dataDiffDetection }) {

    const correctionScaled = Utils.isNumber(correction) ?
        (Utils.toNumber(correction) / viewScale)
            .toLocaleString("de-DE", Config.FLOATING_POINT_PRECISION) : 0

    let shouldDisplayReportedAsLabel = !ObjCheck.isNullUndefinedEmptyOrDash(reportedAsLabel)
        && !labelsSimilarityCheck(filedName, normalizeLabel(filedName), reportedAsLabel)

    const containsMeaningfullData = () => shouldDisplayReportedAsLabel || notes || manuallyCalculated || p2pCalc || fieldSrc !== null || !!dataDiffDetection

    const getContent = () => {
        return <>{shouldDisplayReportedAsLabel && <><b>Reported as:</b> {reportedAsLabel}<br /></>}
            {correction !== "0" && <><b>Correction:</b> {correctionScaled}<br /></>}
            {notes && <><b>Notes:</b> {notes}<br /></>}
            {fieldSrc !== null && <><b>Source:</b> {fieldSrc}<br /></>}
            {calcsDisabled && <><b>Automatic Calculations Disabled</b><br /></>}
            {manuallyCalculated && <><b>Manually Calculated:</b>YES<br /></>}
            {p2pCalc && <><b>Period to Period Calculated:</b>{p2pCalc}<br /></>}
            {dataDiffDetection && (
                <>
                    <b>Data Inconsistency</b>
                    <br />Detection method: {dataDiffDetection}
                    <br />Expectation: {dataDiffExp}
                </>
            )}
        </>
    }

    // Do not use Modal "open" for performance reasons
    return containsMeaningfullData() &&
        <Popup
            content={getContent()}
            header={<>{filedName} <Divider fitted={true} /> </>}
            trigger={<Icon
                className='cellInfoIcon'
                color={getAdditionalInfoIconColor(fieldSrc, reportedAsLabel, notes, manuallyCalculated, p2pCalc, dataDiffDetection)}
                size='small' name='info' />}
        />
}

function ValueField(props) {
    const entry = props.entry
    const validationRes = entry.validData()
    const descriptor = entry.getDescriptor()
    const data = entry.getData()
    const isNumberDescriptor = DescriptorType.isNumber(descriptor)
    const calculatedInputScale = entry.getEffectiveInputScale()
    const fieldValue = (isNumberDescriptor && Utils.isNumber(data)) ?
        (Utils.toNumber(data) / calculatedInputScale)
            .toLocaleString("de-DE", { maximumFractionDigits: 12 }) : data

    let pasting = false;

    const onChange = (newVal) => {
        if (DescriptorType.isPercent(descriptor)) {
            newVal = normalizePercentVal(newVal, pasting)
        } else if (isNumberDescriptor) {
            //1.000,1
            newVal = normalizeNumberVal(newVal, pasting)
            if (Utils.isNumber(newVal)) {
                newVal = Utils.toReadMode(Utils.toNumber(newVal) * calculatedInputScale, Config.FLOATING_POINT_PRECISION)
                //1000,1
            }
        }
        pasting = false
        entry.setData(newVal)
    }

    return <Form.Field disabled={entry.disabled} className={validationRes === VALID ? "" : 'invalidCellValue-' + validationRes} >
        <input type='text' placeholder={props.placeholder}
            value={fieldValue}
            onPaste={v => pasting = true}
            onChange={v => onChange(v.target.value)} />
    </Form.Field>
}

function SelectField({ entry, predefinedValues }) {
    const validarionRes = entry.validData()
    const descriptor = entry.getDescriptor()

    const options = predefinedValues
        .map(o => { return { key: o.key, text: o.value, value: o.key } })

    return <Form.Select
        basic={true}
        options={options}
        placeholder={descriptor.label}
        value={entry.getData()}
        onChange={(c, v) => entry.setData(v.value)}
        search
        className={validarionRes === VALID ? "selectField" : 'selectField invalidCellValue-' + validarionRes}
    />
}

function EditField({ entry, showSource }) {
    const descriptor = entry.getDescriptor()
    const predefinedValues = DescriptorUtils.predefinedValues(descriptor)
    const isSelectField = predefinedValues.length > 0
    const isHeadline = DescriptorType.isHeadline(descriptor)
    const primaryField = isSelectField ?
        <SelectField {...{ entry, predefinedValues }} /> :
        <ValueField entry={entry} placeholder={isHeadline ? "" : "Value"} />

    const metadata = entry.getMetadata()

    return <Form>
        <EditMetadataFormWrapper {...metadata} data={entry.getData()}
            onSave={newMetadata => entry.setMetadata(Object.assign({}, metadata, newMetadata))}
            fieldName={descriptor[DescriptorProps.LABEL]}
            fieldDescriptor={descriptor}
            allowWarns={descriptor[DescriptorProps.DYNAMIC] && !isHeadline} />

        {isSelectField || isHeadline ? primaryField :
            <>
                <ReportedAsLabelCellField entry={entry} />
                {primaryField}
                <CorrectionCellField entry={entry} />
                <CellInputScaleField entry={entry}
                    disabled={!DescriptorType.isNumber(descriptor)} />
                <ExtendedSearchField entry={entry} />
                {showSource && <SourceField entry={entry} />}
            </>}
    </Form>
}

function CellInputScaleField({ entry, disabled }) {
    const ops = ScaleUtil.scalePairs.filter(p => p.key !== 0)

    return <Form.Select size="mini" upward disabled={disabled}
        basic={true}
        options={ops.map(o => { return { key: o.key, text: o.value, value: o.key } })}
        value={entry.getEffectiveInputScale()}
        onChange={(c, v) => entry.setFieldInputScale(v.value, true)}
    />
}

function ExtendedSearchField({ entry }) {
    return <Form.Checkbox size="mini"
        checked={entry.getExtendedSearch()}
        onChange={(e, data) => entry.setExtendedSearch(data.checked)} />
}

function SourceField({ entry }) {
    /**
     * Changing of the entity source does not cause rerendering of the whole
     * column. This is why we need tp fore rerendering of the SourceField copmonent
     */
    const [counter, setCounter] = useState(0);

    const ops = SrcFieldValue.ALL_PERSISTED
        .filter(v => v !== SrcFieldValue.MIXED)
        .map(s => { return { key: s, text: s, value: s } })
    ops.push({ key: "", text: "", value: "" })

    return <Form.Select className='fieldSrcSelect'
        size="mini" upward
        basic={true}
        options={ops}
        value={entry.getSource()}
        onChange={(c, v) => {
            const val = v.value
            entry.setSource(val === "" ? null : val)
            setCounter(counter + 1)
        }}
    />
}

function ReportedAsLabelCellField(props) {
    const entry = props.entry
    const metadata = entry.getMetadata()

    return <Form.Field>
        <input type='text' placeholder='Reported as Label'
            value={metadata[MetadataField.REPORTED_AS_LABEL]}
            onChange={v => entry.setMetadata(Object.assign({}, metadata,
                { [MetadataField.REPORTED_AS_LABEL]: v.target.value }))} />
    </Form.Field>
}

function CorrectionCellField(props) {
    const entry = props.entry
    const metadata = entry.getMetadata()
    const correctionProp = "correction"

    const calculatedInputScale = entry.getEffectiveInputScale()
    const correction = metadata[correctionProp]
    const correctionScaled = (Utils.isNumber(correction)) ?
        (Utils.toNumber(correction) / calculatedInputScale)
            .toLocaleString("de-DE", { maximumFractionDigits: 12 }) : correction

    let pasting = false
    const onChange = newValRaw => {
        const val = normalizeNumberVal(newValRaw, pasting)
        const newVal = Utils.isNumber(val) ? Utils.toReadMode(Utils.toNumber(val) * calculatedInputScale, Config.FLOATING_POINT_PRECISION) : val
        pasting = false
        entry.setMetadata(Object.assign({}, metadata, { [correctionProp]: newVal }))
    }
    return <Form.Field className={entry.validMetadata() ? "" : 'invalidCellValue-' + INVALID} >
        <input type='text'
            onPaste={v => pasting = true}
            value={correctionScaled}
            disabled={entry.correctionDisabled()}
            onChange={v => onChange(v.target.value)} />
    </Form.Field>
}

function ViewField({ entry, style, content }) {
    const descriptor = entry.getDescriptor();
    let normalizedContent
    if (Utils.isNumber(content) && DescriptorType.isNumber(descriptor)) {
        const num = Utils.toNumber(content)
        normalizedContent = num.toLocaleString("de-DE", { maximumFractionDigits: Config.FLOATING_POINT_PRECISION })
    } else if (DescriptorType.isPercent(descriptor) && !ObjCheck.isNullUndefinedEmptyOrDash(content)) {
        try {
            const percentNum = Utils.toNumber(content.replace("%", ""), false)
            normalizedContent = percentNum.toLocaleString("de-DE", { maximumFractionDigits: 2 }) + "%"
        } catch (e) {
            Assert.fail("Unexpected percent value (EDIT):" + content)
        }
    } else {
        normalizedContent = content
    }

    //The outer style is needed to handle some use cases when the cell does not have value
    // text decoration none is added so that the icon does not have any underscores
    return <div style={{ ...style, textDecoration: "none" }}>
        <FieldInfoModal
            {...entry.getMetadata()}
            filedName={descriptor.label}
            viewScale={entry.getViewScale()}
            fieldSrc={entry.getSource()} />
        <div style={style}>{normalizedContent}</div>
    </div>
}


const extensionIconsMap = { pdf: "file pdf outline", png: "file image outline" }

function ResourceUrlsPopup({ listSpecProvider, className, position, schemaName,
    allowEdit, allowPreview }) {
    const [resourcesModel, setResourcesModel] = React.useState(null)
    const [editModal, setEditModal] = React.useState(null)
    const [opened, setOpened] = React.useState(false)

    function resourceEntity(rInfo, idx) {
        const previewUrl = allowPreview ? (rInfo.serverUrl + "/" + rInfo.path) : "/notEnoughPriviliges"
        return <div key={idx} >
            {allowEdit &&
                <Icon className='inlineResourceEdit' name="edit" onClick={() => {
                    ResourceRepo.get(rInfo.path, resource => {
                        setOpened(false)
                        setEditModal(<EditResourceModalV2 resource={resource}
                            onClose={() => {
                                setEditModal(null)
                                setOpened(true)
                            }}
                            onSave={newPath => {
                                ResourceRepo.relocateReportResources({
                                    oldPath: rInfo.path, newPath
                                }, () => {
                                    setEditModal(null)
                                    reloadResources()
                                    setOpened(true)
                                })
                            }} />)
                    })
                }} />}

            <a href={previewUrl}
                target="_blank" rel="noopener noreferrer" >
                <Icon name={extensionIconsMap[rInfo.extension] || "file outline"} />
                {rInfo.name}
            </a>
        </div >
    }
    function content() {
        if (resourcesModel === null) {
            return <Loader active inline='centered' />
        } else if (resourcesModel.empty) {
            return <p>Resources not found</p>
        }

        function getView(name, resources) {
            return resources.length > 0 ? <><b>{name}</b>
                {resources}</> : null
        }
        return <>
            <a target="_blank" rel="noopener noreferrer" href={"/resources/preview/" + btoa(JSON.stringify(listSpecProvider()))}>Open Preview</a>
            <br />
            {getView("Documents", resourcesModel.documents.map(resourceEntity))}
            {getView(schemaName + " Images", resourcesModel.contextRelatedTableImages.map(resourceEntity))}
            {getView(schemaName + " Notes Images", resourcesModel.contextRelatedNotesImages.map(resourceEntity))}
            {getView("Other Images", resourcesModel.otherImages.map(resourceEntity))}
        </>
    }

    function toResourcesModel(resources) {
        const model = {
            documents: [],
            contextRelatedTableImages: [],
            contextRelatedNotesImages: [],
            otherImages: [],
            empty: resources.length === 0
        }

        //TODO the integrity must be ensured by the tool and the normalization must be dropped 
        function normalizeSchemaName(schemaName) {
            let normalizedSchemaName = schemaName.toLowerCase()

            if (normalizedSchemaName === "kpis") {
                normalizedSchemaName = "kpi"
            }

            return normalizedSchemaName
        }

        const normalizedSchemaName = normalizeSchemaName(schemaName)
        resources.forEach(serverResource => {
            const path = serverResource.path
            const serverUrl = serverResource.serverUrl
            const resourceProps = toResourceProps(path)
            const resource = {
                serverUrl, path, name: ResourceProps.userFriendlyName(resourceProps),
                extension: resourceProps.fileExtension,
            }

            const finDocType = resourceProps.finDocType
            if (resourceProps.fileExtension !== "png") {
                model.documents.push(resource)
            } else if (finDocType.toLowerCase().startsWith(normalizedSchemaName)) {

                if (resourceProps.note) {
                    model.contextRelatedNotesImages.push(resource)
                } else {
                    model.contextRelatedTableImages.push(resource)
                }
            } else {
                model.otherImages.push(resource)
            }
        })


        //TODO Do it at once
        sortResourceInfos(model.documents)
        sortResourceInfos(model.contextRelatedTableImages)
        sortResourceInfos(model.contextRelatedNotesImages)
        sortResourceInfos(model.otherImages)

        return model
    }

    const reloadResources = () => ResourceRepo.listReportResources(
        listSpecProvider(), resources => {
            setResourcesModel(toResourcesModel(resources))
        }, err => {
            console.error("Failed to load resources" + err)
            setResourcesModel(toResourcesModel([]))
        })

    return <>
        <Popup position={position} on='click' flowing
            hideOnScroll={true}
            onOpen={() => {
                setOpened(true)
                reloadResources()
            }}
            open={opened}
            onClose={() => setOpened(false)}
            trigger={<Icon className={'cursorPointer ' + className} color='grey'
                size='small' name='file alternate outline' />} wide>
            {content()}
        </Popup>
        {editModal}
    </>
}


export { EditField, ViewField, ResourceUrlsPopup }