import TableActionButton from '../tableActionButton';
import './advancedSettingsToggle.styles.css';

const AdvancedSettingsToggle = ({ showAdvancedSettings, toggleAdvancedSettings }) => {
    return (
        <TableActionButton className="advanced-settings-btn" onClick={toggleAdvancedSettings}>
            {showAdvancedSettings ? 'Hide Advanced Settings' : 'Show Advanced Settings'}
        </TableActionButton>
    );
};

export default AdvancedSettingsToggle;
