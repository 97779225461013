import { Card, Icon } from "semantic-ui-react"


function Description({ description }) {
    return <Card>
        <Card.Content>
            <Card.Header className="smallHardHeader">Description</Card.Header>
            <Card.Description>
                {description}
            </Card.Description>
        </Card.Content>
    </Card >
}

export { Description }