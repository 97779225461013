import ReportUtils from "../../../utils/reportUtils";
import { ReportField, ReportFillingType, SrcFieldValue } from "../../../constnats/reportConstants";
import { ConfigProps } from "./constants";
import Alert from "../../../utils/alert";
import Assert from "../../../utils/asserts";
import CloneUtils from "../../../utils/cloneUtils";

/**
 * Checkers for redundancy of original report versions.
 * 
 * Every checker accepts a report to be checked and a map of all existing
 * reports that are going to be rendered. If the report is redundant the
 * checks return true, otherwise false.
 */
const ORIGINAL_V_REDUNDANCY_CHECKS = {
    [ConfigProps.CONDITIONAL_ORIGINAL_DISABLED]: () => false,
    [ConfigProps.HIDE_ORIGINAL_IF_SAME_AS_LATEST]:
        (originalReport, reportsBag) => {
            const latest = reportsBag.getLatestVersionOf(originalReport)
            return latest && latest.originalToLatestDiff !== true
        },
    [ConfigProps.HIDE_ORIGINAL_IF_LATEST_PRESENTED]:
        (originalReport, reportsBag) => reportsBag.getLatestVersionOf(originalReport) !== undefined,
    [ConfigProps.CONDITIONAL_ORIGINAL_BASED_ON_FUZZY_DIFF]:
        (originalReport, reportsBag) => {
            const latest = reportsBag.getLatestVersionOf(originalReport)
            return latest !== undefined && latest.originalToLatestFuzzyDiff !== true
        }
}

class ReportsBag {

    /**
     * @param {[]} reports Reports to create a bag for. Required
     */
    constructor(reports) {
        this.reportsMap = this._mapReports(reports)
    }

    /**
     * @param {Object} report 
     *
     * @returns true if the report exists in the bag, otherwise false
     */
    contains(report) {
        return this.reportsMap[this._getKey(report)] !== undefined
    }

    /**
     * @param {Object} report Required
     * @returns calced report version or undefined if ont found
     */
    getCalcedVersionOf(report) {
        return this.reportsMap[this._getKey(report, { calced: true })]
    }

    /**
     * @param {Object} report Required
     * @returns non calced report version or undefined if ont found
     */
    getNonCalcedVersionOf(report) {
        return this.reportsMap[this._getKey(report, { calced: false })]
    }

    /**
     * @param {Object} report Required
     * @returns original report version or undefined if ont found
     */
    getOriginalVersionOf(report) {
        return this.reportsMap[this._getKey(report, { version: ReportFillingType.ORIGINAL_FILLING })]
    }

    /**
     * @param {Object} report Required
     * @returns latest report version or undefined if ont found
     */
    getLatestVersionOf(report) {
        return this.reportsMap[this._getKey(report, { version: ReportFillingType.LATEST_FILLING })]
    }

    _mapReports(reports) {
        const reportsMap = {};
        for (const report of reports) {
            const key = this._getKey(report)
            const existingReport = reportsMap[key]
            if (existingReport) {
                const existingReportSrcIsUserInfo = existingReport[ReportField.SRC] === SrcFieldValue.USER_INFO
                Assert.trueVal(report[ReportField.SRC] === SrcFieldValue.USER_INFO
                    || existingReportSrcIsUserInfo)

                /**
                 * Non USER_INFO has priority over USER_INFO reports.
                 */
                if (existingReportSrcIsUserInfo) {
                    reportsMap[key] = report
                }

            } else {
                reportsMap[key] = report
            }

        }

        return reportsMap
    }

    _getKey(r, { version, calced } = {}) {
        calced = calced === undefined ? ReportUtils.isCalced(r) : calced
        version = version === undefined ? r[ReportField.TYPE] : version

        return r[ReportField.YEAR] + r[ReportField.PERIOD] + r[ReportField.REPORT_TYPE] + version + calced
    }
}


/**
 * @param {[]} reports Reports to fill in missing values based on
 * calced reports. Required
 * @param {ReportsBag} reportsBag Reports bag where to search for calced
 * reports. Required
 * @param {[]} dynamicFlatDescriptors Required
 * 
 * NOTE: The function mutates the report 
 */
function fillInMissingValues(reports, reportsBag, dynamicFlatDescriptors) {
    const nonCalculatedReports = reports.filter(r => !ReportUtils.isCalced(r))
    for (const report of nonCalculatedReports) {
        const calcedReport = reportsBag.getCalcedVersionOf(report)
        if (calcedReport !== undefined) {
            for (const flatDescriptor of dynamicFlatDescriptors) {
                ReportUtils.fillInMissingValues(report, calcedReport, flatDescriptor)
            }
        }
    }
}

/**
 * @param {[]} reports Reports to fill in diffs for. Required
 * @param {ReportsBag} reportsBag Reports bag where to search for diffs. Required
 * @param {[]} dynamicFlatDescriptors Required
 * @param {{}} advancedTableConfig Required
 * 
 * NOTE: The function mutates the reports. 
 */
function fillInDiffs(reports, reportsBag, dynamicFlatDescriptors, advancedTableConfig) {
    const diffThreshold = Number(advancedTableConfig[ConfigProps.DIFF_DETECTION_THRESHOLD])
    const diffIgnoreZeroAndDash = advancedTableConfig[ConfigProps.DIFF_DETECTION_IGNORE_ZERO_DASH]


    /**
     * Fill in diffs in non calced reports based on calced reports
     */
    const nonCalculatedReports = reports.filter(r => !ReportUtils.isCalced(r))
    for (const report of nonCalculatedReports) {
        const calcedReport = reportsBag.getCalcedVersionOf(report)
        if (calcedReport !== undefined) {
            ReportUtils.markDiffs(calcedReport, report, dynamicFlatDescriptors,
                diffThreshold, diffIgnoreZeroAndDash)
        }
    }


    /**
     * Fill in diffs in latest reports based on original reports.
     * 
     * Known Issue: The diffs form the latest reports override the diffs from the calculated reports.
     */
    const latestReports = reports.filter(r => r[ReportField.TYPE] === ReportFillingType.LATEST_FILLING)
    for (const latestReport of latestReports) {
        const originalReport = reportsBag.getOriginalVersionOf(latestReport)
        if (originalReport !== undefined) {
            ReportUtils.markDiffs(originalReport, latestReport,
                dynamicFlatDescriptors, diffThreshold, diffIgnoreZeroAndDash)

            /**
             * The fuzzy diff is used for two purposes. The first purpose is to
             * warn the users for data inconsistency using the red icons. 
             * The second is to filter the original reports based on their
             * differences with the latest. This is why we need to know if there
             * is a difference specifically between the latest and the original reports.
             */
            latestReport.originalToLatestDiff = latestReport.hasDiff
            latestReport.originalToLatestFuzzyDiff = latestReport.hasFuzzyDiff
        }
    }
}

/**
 * Filters all reports that represent a subset of other reports.
 * NOTE: Subset of persisted reports can occur because of a P2P calculation of
 * non cumulative data. 
 *
 * @param {[]} reports Reports to be filtered
 * @returns not-null array of filtered reports
 */
function filterSubsetReports(reports) {
    const reportsBag = new ReportsBag(reports)

    /**
     * NOTE: There is a bug here. If the system has more than one subset reports
     * and the src report is not present than all subset reports are going to be presented.
     */
    return reports
        .filter(r => r.subsetOfReport === undefined || !reportsBag.contains(r.subsetOfReport))
}

/**
 * TODO Rename to apply table/reports settings
 *
 * @param {[]} reports Required 
 * @param {{}} filterCfg Required
 * @param {{}} advancedTableConfig Required 
 * @param {{}} dynamicFlatDescriptors Required
 * @returns not-null array of reports
 */
function applyFilterCfg(reports, filterCfg, advancedTableConfig,
    dynamicFlatDescriptors) {

    reports = CloneUtils.deepClone(reports)

    const reportsBag = new ReportsBag(reports)

    const originalReportRedundancyCheck = ORIGINAL_V_REDUNDANCY_CHECKS[filterCfg[ConfigProps.ORIGINAL_VERSION_CONDITIONAL_FILTER]]
    Assert.notNullOrUndefined(originalReportRedundancyCheck, "originalReportRedundancyCheck")

    // Show/Hide calculated reports
    const showCalculatedStatements = advancedTableConfig[ConfigProps.SHOW_CALCULATED_STATEMENTS] === true
    const { from, to } = filterCfg[ConfigProps.YEARS_FILTER]

    reports = reports
        // Periods filtering [Q1, Q2, H1,...]
        .filter(r => filterCfg[ConfigProps.PERIODS_FILTER].includes(r[ReportField.PERIOD]))
        // Filtering by report version [Latest, Original]
        .filter(r => filterCfg[ConfigProps.FILLING_TYPE_FILTER].includes(r[ReportField.TYPE]))
        // Filter by report format [Proforma, Standard, Condensed]
        .filter(r => filterCfg[ConfigProps.REPORT_TYPES_FILTER].includes(r[ReportField.REPORT_TYPE]))
        .filter(r => r[ReportField.YEAR] >= from && r[ReportField.YEAR] <= to)
        // Filtering calculated reports
        .filter(r => !ReportUtils.isCalced(r) || showCalculatedStatements)
        // Filters the calculated reports that have alternatives as persisted reports.
        .filter(r => !ReportUtils.isCalced(r) || !reportsBag.getNonCalcedVersionOf(r))

    /**
     * Fills in the missing values of the non calculated reports that can be found
     * in the calculated reports.
     */
    fillInMissingValues(reports, reportsBag, dynamicFlatDescriptors)

    /**
     * The diff fill in must be executed before the redundancy filtering
     * because most of the reports needed for it will be filtered in it.
     */
    fillInDiffs(reports, reportsBag, dynamicFlatDescriptors, advancedTableConfig)

    /**
     * Filters the original reports based in "Conditionally Hide Original Versions" settings. 
     * It must be executed after the diffs configuration because it depends on the diffs. 
     */
    reports = reports.filter(r => r[ReportField.TYPE] === ReportFillingType.ORIGINAL_FILLING ?
        !originalReportRedundancyCheck(r, reportsBag) : true)

    return filterSubsetReports(reports)
}

export { applyFilterCfg }