import React from 'react';
import SchemaRepo from '../../../../../../utils/repository/schemaRepo'
import SchemaForm from './schemaForm';
import Alert from '../../../../../../utils/alert';
import SchemaViewData from '../../../../../../utils/repository/ui/schemaViewData'
import Loader from '../../../../../../components/loader';
import { Option } from './schemaOptions';
import { ChangeLogModal, SchemaChangeDescription } from '../../../../../../components/admin/changeLog';
import { DataCalcType } from './descriptors/models/descriptorModel';
import { SchemaField } from '../../../../../../constnats/schemaConstants';
import MergeHistoryModal from './mergeHistoryModal';
import Utils from '../../../../../../utils/descriptor/descriptorUtils';

class EditReportSchemaFormWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            schemaView: null,
            isLoading: true,
            modal: <></>
        }
        this.save = this.save.bind(this);
        this.delete = this.delete.bind(this);
    }

    componentDidMount() {
        //TODO The schema is laded on meny levels. It requires refactoring
        SchemaViewData.getFormData(this.props.schema.id, schemaView => {
            Utils.addFullCategories(schemaView.schema.descriptors, () => {
                this.setState({ schemaView, isLoading: false })
            }) 
        })
    }

   

    save(schema, onSaved) {
        SchemaRepo.update(schema, schema => {
            Alert.success("Saved")
            this.props.setDirty(false)
            //TODO Update
            if (onSaved !== undefined) {
                onSaved()
            }
            this.props.onSchemaUpdated()
        })
    }

    delete() {
        SchemaRepo.delete(this.props.schema.id, () => {
            Alert.success("Deleted")
            this.props.setDirty(false)
            this.props.onSchemaDeleted()
        })
    }

    openChangeLogModal() {
        const flatDescriptors = Utils.flatDescriptors(this.state.schemaView.schema.descriptors)
        this.openModal(<ChangeLogModal
            entityId={this.props.schema.id}
            onCancel={() => this.closeModal()}
            flatDescriptors={flatDescriptors}
            descriptionCompType={SchemaChangeDescription}
        />)
    }

    openMergeHistory() {
        this.openModal(<MergeHistoryModal schemaId={this.props.schema.id} onCancel={() => this.closeModal()} />)
    }

    contextMenuOps() {
        const contextMenuOps = [new Option("Change Log", "history", () => this.openChangeLogModal()),
        new Option("Merge History", "history", () => this.openMergeHistory())]

        return contextMenuOps
    }

    closeModal() {
        this.setState({ modal: <></> })
    }

    openModal(modal) {
        this.setState({ modal: modal })
    }


    render() {
        const schemaView = this.state.schemaView
        return (
            this.state.isLoading ? <Loader /> : <>
                {this.state.modal}
                <SchemaForm
                    schema={schemaView.schema}
                    options={schemaView.options}
                    onChange={() => this.props.setDirty(true)}
                    save={this.save}
                    delete={this.delete}
                    contextMenuOps={this.contextMenuOps()} />
            </>
        );
    }
}

class NewReportSchemaFormWrapper extends React.Component {
    constructor(props) {
        super(props);

        this.schema = {
            id: "new",
            name: "",
            descriptors: [],
            [SchemaField.DEF_DATA_CALC_TYPE]: DataCalcType.NON_CUMULATIVE,
            [SchemaField.DEF_SCALE]: 1000
        }

        this.save = this.save.bind(this);
    }

    onSaved(schema, onSaved) {
        Alert.success("Saved")
        this.props.setDirty(false)
        this.props.onSaved(schema)
        if (onSaved !== undefined) {
            onSaved()
        }
    }

    save(schema, onSaved) {
        const createSchemaSpec = Object.assign({}, schema, {
            companyId: this.props.companyId,
            weight: this.props.weight
        })

        if (this.props.companyId === 0) {
            SchemaRepo.saveTemplate(createSchemaSpec, schema => this.onSaved(schema, onSaved))
        } else {
            SchemaRepo.save(createSchemaSpec, schema => this.onSaved(schema, onSaved))
        }
    }

    render() {
        return (
            <SchemaForm schema={this.schema}
                onChange={() => this.props.setDirty(true)}
                save={this.save}
                contextMenuOps={[]}
                options={{
                    updateDescriptors: false,
                    updateName: true,
                    createMode: true
                }} />
        );
    }
}

export { NewReportSchemaFormWrapper, EditReportSchemaFormWrapper }


