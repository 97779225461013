import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { Chart } from "react-google-charts";
import ObjCheck from '../../../../utils/objCheck';
import Assert from '../../../../utils/asserts';
import { MultiselectField } from '../../../../components/financialTables/configuration/fields';
import { ReportField } from '../../../../constnats/reportConstants';
import KeyValue from '../../../../structures/keyValePair';
import { DescriptorType } from '../../../admin/company/components/financialTables/schema/descriptors/models/descriptorModel';
import ReportUtils from '../../../../utils/reportUtils';

const DEFAULT_CART_CFG_OPS = {
    fontSize: 12,
    chartArea: {
        left: 50,
        right: 10,
        top: 10,
        bottom: 45
    },
    chart: {
        title: '-'

    },
    tooltip: {
        textStyle:
        {
            fontName: 'verdana',
            fontSize: 11
        }
    },
    legend: {
        position: "bottom",
        textStyle: {
            fontSize: 10,
            maxLines: 3,
        }
    },
    vAxis: {
        // scaleType: 'log',
        format: 'short'
    },
    focusTarget: 'category',
    crosshair: { orientation: 'vertical', trigger: 'focus' },

}
function dataToNumber(data) {
    if (typeof data === "string") {
        data = Number(data.replace(",", ".").replace("%", ""))
    } else {
        Assert.typeNumber(data, "dataToNumber")
    }

    return data
}

function chartableDescriptorsMatcher(descriptor) {
    return DescriptorType.isNumber(descriptor) || DescriptorType.isPercent(descriptor)
}

function getChartData(reports, selectedPeriods, filedsCfg, annotations) {
    const chartReports = reports.filter(r => selectedPeriods.includes(r[ReportField.PERIOD]))

    const fieldIdsToChart = Object.keys(filedsCfg)
        .filter(id => filedsCfg[id].chartSign !== 0)
        .filter(id => chartReports.some(r => ReportUtils.fieldHasValueOrP2PCalc(r, id)))

    const chartConfigColumns = ["Year"]
    for (const id of fieldIdsToChart) {
        chartConfigColumns.push(filedsCfg[id].label)
        if (annotations) {
            chartConfigColumns.push({ type: 'string', role: 'annotation' })
        }
    }

    const chartData = [chartConfigColumns]
    const preVals = {}

    for (const report of chartReports) {
        const periodData = [String(report.year).slice(2) + " " + report[ReportField.PERIOD]]
        for (const id of fieldIdsToChart) { 
            const rawValue = ReportUtils.getFieldValueOrP2PCalc(report, id)

            if (ObjCheck.isNullUndefinedEmptyOrDash(rawValue)) {
                periodData.push(null)
                if (annotations) {
                    periodData.push("-%")
                }
            } else {
                const value = dataToNumber(rawValue) * filedsCfg[id].chartSign
                periodData.push(value)

                if (annotations) {
                    let percentChange = ""
                    if (preVals[id] === undefined && typeof value === 'number') {
                        preVals[id] = value
                    }
                    if (preVals[id] !== undefined) {
                        percentChange = (((value - preVals[id]) / Math.abs(preVals[id])) * 100).toFixed(2) + "%"
                    }

                    periodData.push(percentChange)

                    preVals[id] = value
                }
            }
        }

        chartData.push(periodData)
    }

    return chartData
}

function getDistinctPeriods(reports) {
    const periods = []
    for (const report of reports) {
        const period = report[ReportField.PERIOD]
        if (!periods.includes(period)) {
            periods.push(period)
        }
    }

    return periods
}

const ANNOTATIONS = "Annotations"

function TableChart({ reports, dynamicFlatDescriptors }) {
    const periods = useMemo(() => {
        return getDistinctPeriods(reports)
    }, [reports])

    const [selectedPeriods, setSelectedPeriods] = useState([]);
    const [chartCfg, setChartCfg] = useState({ [ANNOTATIONS]: true });
    const [filedsCfg, setFiledsCfg] = useState({});


    useEffect(() => {
        const cfg = {}

        const descriptors = dynamicFlatDescriptors.filter(chartableDescriptorsMatcher)
        for (const desc of descriptors) {
            cfg[desc.id] = filedsCfg[desc.id] || {
                label: desc.label,
                chartSign: 0
            }
        }

        if (descriptors.length > 0) {
            cfg[descriptors[0].id].chartSign = 1
        }

        setFiledsCfg(cfg)

        const newSelectedPeriods = selectedPeriods.filter(sp => periods.includes(sp))
        if (newSelectedPeriods.length !== selectedPeriods.length) {
            setSelectedPeriods(newSelectedPeriods)
        } else if (selectedPeriods.length === 0) {
            if(periods.includes("FY")){
                setSelectedPeriods(["FY"])
            }else if(periods.length > 0){
                setSelectedPeriods([periods[0]])
            } 
        }

    }, [dynamicFlatDescriptors, selectedPeriods])

    const chartData = getChartData(reports, selectedPeriods, filedsCfg, chartCfg[ANNOTATIONS] === true)

    const hasChartData = chartData[0]?.length > 1

    function changeChartSign(id, newSign) {
        setFiledsCfg({ ...filedsCfg, [id]: { ...filedsCfg[id], chartSign: newSign } })
    }

    return (
        <div className='tableChartWrapper'>
            <MultiselectField
                selected={selectedPeriods.slice()}
                optionsKV={periods.map(KeyValue.createSame)}
                onChange={setSelectedPeriods} />

            <div className="floatRight">
                <MultiselectField
                    selected={Object.keys(chartCfg).slice()}
                    optionsKV={[KeyValue.createSame(ANNOTATIONS)]}
                    onChange={values => setChartCfg(values.reduce((a, v) => ({ ...a, [v]: true }), {}))} />
            </div>
            <div>
                <table className='chartPropsTable' >
                    <tbody>
                        {Object.keys(filedsCfg).map(dId => {
                            const chartSign = filedsCfg[dId].chartSign
                            // const normalizedVal = value === undefined ? 0 : value
                            const signIconColor = chartSign === 0 ? "" : chartSign === 1 ? "green" : "red"
                            const signIconName = chartSign === 0 ? "" : chartSign === 1 ? "plus" : "minus"
                            return <tr
                                key={dId}
                                onClick={() => changeChartSign(dId, chartSign === 0 ? 1 : 0)}
                                className='cursorPointer'>
                                <td>
                                    {chartSign !== 0 && <Icon size="tiny" name="checkmark" />}
                                </td>
                                <td onClick={(e) => {
                                    e.stopPropagation();
                                    e.nativeEvent.stopImmediatePropagation();
                                    changeChartSign(dId, chartSign === 1 ? -1 : 1)
                                }}>
                                    {chartSign !== 0 && <Icon color={signIconColor} size="tiny" name={signIconName} />}
                                </td>
                                <td>
                                    {filedsCfg[dId].label}
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>

                <div className='generalChartWrapper'>
                    {hasChartData ?
                        <Chart
                            chartType="LineChart"
                            width="100%"
                            height="450px"
                            data={chartData}
                            options={DEFAULT_CART_CFG_OPS}
                        /> :
                        "No Chart Data"}
                </div>
            </div>
        </div>
    )
}


export default TableChart