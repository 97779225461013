import { Form, TextArea } from "semantic-ui-react"
import PropTypes from 'prop-types'
import Assert from "../../utils/asserts";

function SelectField({ value, number, onChange, label, valuesMap, className,
    error = false, disabled = false }) {


    let normalize = v => v;
    if (typeof value === "boolean") {
        Assert.trueVal(!number, "Unexpected select value")
        normalize = val => String(val)
    } else if (number) {
        normalize = val => Number(val)
    }

    return <Form.Field {...{ value, className, label, error, disabled }}
        onChange={e => onChange(normalize(e.target.value))}
        control='select'>
        {valuesMap.map(kv =>
            <option key={kv.key} value={normalize(kv.key)}>{kv.value}</option>)}
    </Form.Field>
}

SelectField.propTypes = {

    /**
     * Selected value.
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),

    /**
     * True of the the values needs to be converted to  Numbers.
     */
    number: PropTypes.bool,

    /**
    * Func to be called if the value is  changed.
    */
    onChange: PropTypes.func.isRequired,

    /**
     * Label of the field. 
     */
    label: PropTypes.string.isRequired,

    /**
     * posible options in format repreented by  KeyValues 
     */
    valuesMap: PropTypes.array.isRequired,

}


function TextField({ value, onChange, label, error = false, disabled = false }) {

    return (
        <Form.Input
            disabled={disabled}
            error={error}
            input="text"
            label={label}
            value={value}
            onChange={(v) => onChange(v.target.value)}
        />
    )
}

function CheckboxField({ value, onChange, label, error = false, disabled = false }) {
    return (
        <Form.Checkbox
            disabled={disabled}
            error={error}
            label={label}
            checked={value === true}
            onChange={(e, d) => onChange(d.checked)}
        />
    )
}

function TextareaField({ value, onChange, label, error = false, disabled = false }) {
    return (
        <Form.Field
            disabled={disabled}    
            error={error}
            label={label}
            control={TextArea}
            value={value}
            onChange={v => onChange(v.target.value)}
        />

    )
}

export { SelectField, TextField, CheckboxField, TextareaField }
