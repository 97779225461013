import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { Form, Button, Segment, Header, Container, TextArea } from 'semantic-ui-react'
import { SelectField } from '../../../../components/form/fields';
import ObjCheck from '../../../../utils/objCheck';
import CompanyRepo from '../../../../utils/repository/companyRepo';
import RouteC from '../../../../constnats/routeConstants';
import KeyValue from '../../../../structures/keyValePair';
import Alert from '../../../../utils/alert';
import FiledsValidator from '../../../../utils/validation/fieldsValidator';
import UserRepo from '../../../../utils/repository/userRepo';
import { ROLES } from '../../../../constnats/user';


const primaryfields = {
    visibility: {
        label: "Visibility",
        predefinedValuesProvider: () =>
            Promise.resolve(["INTERNAL", "PUBLIC"].map(t => new KeyValue(t, t.toLowerCase())))
    },
    name: "Name",
    description: { label: "Description", type: "textarea" },
    currency: {
        label: "Currency",
        predefinedValuesProvider: () =>
            Promise.resolve(["EUR", "CHF", "GBP", "USD", "DKK", "NOK", "SEK"].map(KeyValue.createSame))
    },
    ownerId: {
        label: "Owner", predefinedValuesProvider:
            () =>
                new Promise((resolve, reject) =>
                    UserRepo.list({ userRoles: [ROLES.EMPLOYEE], includeDisabled: false },
                        users => resolve(users.map(t => new KeyValue(t.id, t.fullName))),
                        reject))
    },
    isin: "ISIN",
    internalDocLink: "Internal Documentation Link",
    comments: { label: "Comments", type: "textarea" },
    suspended: { label: "Suspended", type: "checkbox" },

}

const reportingDateFields = {
    nextReportingDate: {
        label: "Date",
        type: "date"
    },
    nextReportingPeriod: {
        label: "Period",
        predefinedValuesProvider: () =>
            Promise.resolve(["1", "2", "3", "4"].map(KeyValue.createSame))
    },
    nextReportingDateNotes: { label: "Notes", type: "textarea" },
    nextReportingDateConfirmedOn: {
        label: "Confirmed On",
        type: "date"
    }
}

const linkfields = {
    investorRelationsLink: "Investor Relations", reportsLink: "Reports",
    reportsHYLink: "Reports Half-Year", reportsQLink: "Reports Quarterly",
    presentationsLink: "Presentations",
    pressReleasesLink: "Press Releases / News",
    financialCalendarLink: "Financial Calendar"
}

const editOnlyFields = ["ownerId"]

const fields = Object.assign({}, primaryfields, linkfields, reportingDateFields)



function getEditSpec(company, specFields) {
    const data = {}
    if (company !== null) {
        company = JSON.parse(JSON.stringify(company))
        Object.keys(specFields).forEach(k => data[k] = company[k] || "")
        data.ownerId = company.owner?.id || 0
    } else {
        Object.keys(specFields).forEach(k => data[k] = "")
        data.visibility = "PUBLIC"
        data.currency = "EUR"
        //TMP fix to pass the validation
        data.ownerId = 1
    }

    return data
}

class BasicData extends React.Component {

    constructor(props) {
        super(props);

        this.filedsValidator = new FiledsValidator(["name", "description", "ownerId", "comments", "nextReportingDateNotes"],
            {
                description: {
                    validate: val => val.length < 255 && val.length > 3,
                    msg: "The description must be shorter than 255 symbols and longer than 3"
                },
                nextReportingDateNotes: {
                    validate: val => val.length < 255,
                    msg: "The reporting date notes must be shorter than 255 symbols"

                },
                ownerId: {
                    validate: val => Number.isInteger(val) && val > 0,
                    msg: "The owner is required."
                },
                comments: {
                    validate: val => val.length < 500,
                    msg: "The comments must be shorter than 500 symbols"
                }

            })

        this.state = {
            data: getEditSpec(this.props.company, fields),
            propsValidation: this.filedsValidator.getValidErrorProps(),
            redirect: null
        }
    }

    save() {
        if (this.validate()) {
            const companySpec = this.state.data
            const company = this.props.company
            if (company !== null) {
                CompanyRepo.update(Object.assign({ id: company.id }, companySpec), company => {
                    Alert.success("Saved")
                    this.props.onSaved(company)
                    this.props.setDirty(false)
                })
            } else {
                CompanyRepo.save(companySpec, company => {
                    Alert.success("Saved")
                    this.props.setDirty(false)
                    this.setState({ redirect: <Navigate to={RouteC.EDIT_COMPANY_CONFIG_INTERNAL + company.id} replace /> })
                })
            }
        }
    }

    validate() {
        const validationRes = this.filedsValidator.validate(this.state.data)
        this.setState({ propsValidation: validationRes.newErrorProps })
        return validationRes.valid
    }

    handeleDataPropChange(prop, newVal) {
        const data = JSON.parse(JSON.stringify(this.state.data))
        data[prop] = newVal
        this.setState({ data, propsValidation: this.filedsValidator.dicardErrorIfExists(prop) })
        this.props.setDirty(true)
    }

    input(prop, descriptor) {
        let input

        const data = this.state.data[prop]
        const error = this.state.propsValidation[this.filedsValidator.getErrorPropName(prop)]
        if (typeof descriptor === 'string') {
            const label = descriptor
            input = <Form.Input className="smallField" key={prop}
                label={(!ObjCheck.isNullUndefinedOrEmpty(data) && data.startsWith("http")) ? <a target="_blank" rel="noreferrer" href={data}>{label}</a> : label}
                placeholder={label}
                onChange={e => {
                    this.handeleDataPropChange(prop, e.target.value)
                }}
                value={data}
                error={error}
            />
        } else {
            const label = descriptor.label

            if (descriptor.type === "date") {
                input = <SemanticDatepicker className="smallField" key={prop}
                    label={label} value={data ? new Date(data) : ""}
                    format='DD/MM/YYYY'
                    onChange={(e, d) => {
                        this.handeleDataPropChange(prop, d.value)
                    }} />
            } else if (descriptor.type === "textarea") {
                input = <Form.Field
                    key={prop}
                    label={label}
                    error={error}
                    control={TextArea}
                    value={data} onChange={v =>
                        this.handeleDataPropChange(prop, v.target.value)
                    } />
            }else if (descriptor.type === "checkbox") {
                input = <Form.Checkbox
                    key={prop}
                    label={label}
                    error={error}
                    checked={data === true} 
                    onChange={(e, d) =>
                      this.handeleDataPropChange(prop, d.checked)
                    } />
            } else {
                input = <LazySelectField key={prop}
                    value={data}
                    error={error}
                    valuesProvider={descriptor.predefinedValuesProvider}
                    label={label}
                    onChange={v => this.handeleDataPropChange(prop, v)} />
            }
        }

        return input
    }

    render() {
        const company = this.props.company
        const createMode = company !== null
        return (
            <Container>
                {this.state.redirect}
                <Segment>
                    <Form className="companyBasicForm">
                        {Object.keys(primaryfields)
                            .filter(f => createMode || !editOnlyFields.includes(f))
                            .map(k => this.input(k, primaryfields[k]))}

                        <Segment>
                            <Header as="h5" >Next Reporting</Header>
                            {Object.keys(reportingDateFields).map(k => this.input(k, reportingDateFields[k]))}
                        </Segment>
                        <Segment>
                            <Header as="h5" >External Resource Links</Header>
                            {Object.keys(linkfields).map(k => this.input(k, linkfields[k]))}
                        </Segment>
                        <Button size='mini' onClick={() => this.save()} primary>Save</Button>
                    </Form>
                </Segment >
                {createMode &&
                    <Link to={RouteC.COMPANY_PUBLIC + company.id}>
                        Public View
                    </Link>}
            </Container>
        )
    }
}

function LazySelectField({ valuesProvider, value, label, error, onChange }) {
    const [values, setValues] = useState([])

    useEffect(() => {
        valuesProvider().then(res => {
            if (!res.map(kv => kv.key).includes(value)) {
                res.push(new KeyValue(value, "UNKNOWN"))
            }
            setValues(res)
        })
    }, [label])

    return (
        <SelectField className="smallField" {...{ value, label, onChange }}
            valuesMap={values} number={Number.isInteger(values[0]?.key)}
            error={error?.content !== undefined} />
    )
}


export default BasicData


