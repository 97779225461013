import React from 'react';
import { Button, Modal } from 'semantic-ui-react'

class NotSavedModal extends React.Component {
    render() {
        return (<Modal
            open={true}
            size='small'>
            <Modal.Header>Confirmation</Modal.Header>
            <Modal.Content>
                <p>Unsaved data will be lost, if you leave the page!</p>
            </Modal.Content>
            <Modal.Actions>
                <Button size='mini' primary onClick={this.props.onConfirm}>
                    Do not leave
                </Button>
                {this.props.onReject ? <Button size='mini' color="red" onClick={this.props.onReject}>
                    Continue Without Saving
                </Button> : <></>}
            </Modal.Actions>
        </Modal>
        );
    }
}

export default NotSavedModal