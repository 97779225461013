import { DescriptorProps } from '../schema/descriptors/models/descriptorModel';
import Utils from '../../../../../../utils/descriptor/descriptorUtils';
import EntityFactory from './cellEntity';
import ObjCheck from '../../../../../../utils/objCheck';
import ReportUtils from '../../../../../../utils/reportUtils';
import { ReportField } from '../../../../../../constnats/reportConstants';
import { Button, Modal, Table } from 'semantic-ui-react';
import ReportsRepo from '../../../../../../utils/repository/repoertsRepo';
import { useState } from 'react';
import Loader from '../../../../../../components/loader';

const REQUIRED_DESCRIPTORS_MAP = {
    [ReportField.YEAR]: "Year",
    [ReportField.PERIOD]: "Period",
    [ReportField.TYPE]: "Filling Type",
    [ReportField.REPORT_TYPE]: "Report Type"
}

function getReportChanges(report, descriptors) {
    // Descriptor ID => { new: newData, old: oldData }
    const reportChanges = {}

    const changeListener = (descriptorChange, entity) => {
        const descriptorId = entity.descriptorId()
        const oldData = report[ReportField.DYNAMIC_FIELDS][descriptorId]
        if (descriptorChange === true) {
            const newData = entity.getData()
            if (newData === "-" &&
                ObjCheck.isNullUndefinedOrEmpty(oldData)) {
                //TODO Find more elegant approach
            } else {
                reportChanges[descriptorId] = { new: newData, old: oldData }
            }
        }
    }

    /**
     * TODO remove object cloning once sure that the report is not modified in the EntryFactory. Assume it is not but needs to be confirmed.
     */
    const dryRunEntries = new EntityFactory(changeListener)
        .create(descriptors, JSON.parse(JSON.stringify(report)))

    for (const entity of dryRunEntries) {
        entity.refresh()
    }

    const updatedReport = ReportUtils
        .cellEntitiesToReport(dryRunEntries, report)

    return { updatedReport, changes: reportChanges }
}

function reportsChangesToMatrix(changedReports, affectedFieldsIds, dynamicFlatDescriptors) {
    // Descriptor ID => Changes
    const allChangesMatrix = {}

    for (const id of Object.keys(REQUIRED_DESCRIPTORS_MAP)) {
        allChangesMatrix[id] = [REQUIRED_DESCRIPTORS_MAP[id]]
    }

    for (const descriptorId of affectedFieldsIds) {
        allChangesMatrix[descriptorId] = []
    }

    for (const changedReport of changedReports) {
        for (const id of Object.keys(REQUIRED_DESCRIPTORS_MAP)) {
            allChangesMatrix[id].push(changedReport.updatedReport[id])
        }

        for (const descriptorId of affectedFieldsIds) {
            const change = changedReport.changes[descriptorId]

            allChangesMatrix[descriptorId].push(change || null)
        }
    }

    const rows = []
    for (const id of Object.keys(REQUIRED_DESCRIPTORS_MAP)) {
        rows.push([...allChangesMatrix[id]])
    }

    for (const descriptor of dynamicFlatDescriptors) {
        if (affectedFieldsIds.has(descriptor.id)) {
            rows.push([descriptor.label, ...allChangesMatrix[descriptor.id]])
        }
    }

    return rows
}


function getChanges(descriptors, reports) {
    descriptors = JSON.parse(JSON.stringify(descriptors))

    const affectedDescriptorIds = new Set()
    const changedReports = []
    for (const report of reports) {
        const reportChanges = getReportChanges(report, descriptors)
        if (Object.keys(reportChanges.changes).length > 0) {
            changedReports.push(reportChanges)
            for (const descriptorId of Object.keys(reportChanges.changes)) {
                affectedDescriptorIds.add(descriptorId)
            }
        }
    }

    const dynamicFlatDescriptors = Utils.flatDescriptors(descriptors)
        .filter(d => d[DescriptorProps.DYNAMIC] === true)


    const rows = reportsChangesToMatrix(changedReports, affectedDescriptorIds, dynamicFlatDescriptors)
    return {
        rows,
        updatedReports: changedReports
            .filter(c => Object.keys(c.changes).length > 0)
            .map(c => c.updatedReport)
    }

}

function saveReports(updatedReports, schemaId, progressListener) {
    saveReport(updatedReports, 0, schemaId, progressListener)
}

function saveReport(updatedReports, idx, schemaId, progressListener) {
    if (updatedReports[idx] !== undefined) {
        ReportsRepo.update(Object.assign({ schemaId }, updatedReports[idx]), res => {
            progressListener.onSaved()
            saveReport(updatedReports, ++idx, schemaId, progressListener)
        })
    }
}

function BatchUpdateModal({ descriptors, schemaId, reports, closeModal, onSaved }) {
    return <BatchUpdateModalInternal {...getChanges(descriptors, reports)}
        closeModal={closeModal} schemaId={schemaId} onSaved={onSaved} />
}

function BatchUpdateModalInternal({ rows, updatedReports, schemaId, closeModal, onSaved }) {
    const [syncInProgress, setSyncInProgress] = useState(false);
    const [progressListener] = useState(() => {
        let updated = 0
        return {
            onSaved: () => {
                if (++updated === updatedReports.length) {
                    setSyncInProgress(false)
                    onSaved()
                }
            }
        }
    })

    const hasChanges = updatedReports.length > 0
    return syncInProgress ? <Loader /> : <Modal size='large' open={true}>
        <Modal.Header>Changes that are going to be applied.</Modal.Header>
        <Modal.Content className="overflowYScroll">
            {hasChanges ?
                <Table celled>
                    <Table.Body>
                        {rows.map((row, rId) =>
                            <Row row={row} rId={rId} />)}
                    </Table.Body>
                </Table> : <>All reports already synchronised with the Model</>}
        </Modal.Content>
        <Modal.Actions>
            {hasChanges &&
                <Button size='mini' primary onClick={() => {
                    setSyncInProgress(true)
                    saveReports(updatedReports, schemaId, progressListener)
                }}>
                    Save Changes
                </Button>}
            <Button size='mini' onClick={closeModal}>
                Close
            </Button>

        </Modal.Actions>
    </Modal>
}

function Row({ row, rId }) {
    return <Table.Row key={rId}>
        {row.map((cellData, cId) =>
            <Table.Cell key={cId}>{
                (typeof cellData === 'string' || cellData === null) ? cellData : <>
                    <p className='redText'>{cellData.old}</p>
                    <p className='greenText'>{cellData.new}</p>
                </>
            }</Table.Cell>
        )}
    </Table.Row>
}

export default BatchUpdateModal