import Config from "../../constnats/config"
import Utils from "../utils"
import Alert from "../alert"
import { DescriptorType } from "../../pages/admin/company/components/financialTables/schema/descriptors/models/descriptorModel"
import ObjCheck from "../objCheck"
import { default as DescriptorUtil } from "../descriptor/descriptorUtils"
import { INVALID, VALID, WARN } from "../../constnats/generalConstants"

class ValidationResult {

    constructor(status, msg = "") {
        this.status = status
        this.msg = msg
    }
}

class DescriptorValidator {

    /**
     * @param {{}} descriptor As presented in the database. Required
     * @param {string} data Required
     * @param {string} dataExpectation Required
     * @param {string} dataCorrection Representation of a floating point number. Required 
     * @returns {ValidationResult} not-null
     */
    static validateData(descriptor, data, dataExpectation, dataCorrection) {
        let result = null
        if (ObjCheck.isNullOrUndefined(data)) {
            result = new ValidationResult(INVALID, "No Data")
        } else if ((ObjCheck.isNullUndefinedOrEmpty(data) && descriptor.required === true)) {
            result = new ValidationResult(INVALID, "Required field " + descriptor.label + " not entered.")
        } else if (DescriptorType.isNumber(descriptor)) {
            result = DescriptorValidator._validateNumber(descriptor, data, dataExpectation, dataCorrection)
        } else if (DescriptorType.isAny(descriptor)) {
            result = new ValidationResult(VALID)
        } else if (DescriptorType.isPercent(descriptor)) {
            result = DescriptorValidator._validatePercent(descriptor, data, dataExpectation, dataCorrection)
        } else if (DescriptorType.isHeadline(descriptor)) {
            result = DescriptorValidator._validateHeadline(data)
        } else {
            const type = descriptor.type
            result = new ValidationResult(INVALID, "Unknown descriptor type:" + type)
            Alert.error("Unexpected descriptor type:" + type)
        }

        return result
    }

    static _validatePercent(descriptor, data, dataExpectation, dataCorrection) {
        if (typeof data === 'string' && data.endsWith("%")) {
            return DescriptorValidator._validateNumber(descriptor, data.slice(0, -1), dataExpectation, dataCorrection)
        } else if (data === "-" || data === "") {
            return new ValidationResult(VALID)
        } else {
            return new ValidationResult(INVALID, "Invalid percent data: " + data)
        }
    }

    static _validateNumber(descriptor, data, dataExpectation, dataCorrection) {
        const isValid = () => {
            if (typeof data === 'string') {
                if (data.includes(".")) {
                    return false
                }
                if (data.includes(",") && data.length - data.indexOf(",") > Config.FLOATING_POINT_PRECISION + 1) {
                    return false
                }
            }

            return Utils.isNumber(data)
        }
        if (data === "-" || data === "") {
            if (DescriptorUtil.hasSecondaryCalculation(descriptor) && !dataExpectation === "") {
                return new ValidationResult(INVALID, "Secondary calculation does not match the primary one " + data)
            }
        } else {
            if (!isValid()) {
                return new ValidationResult(INVALID, "Invalid number data: " + data)
            } else if (DescriptorUtil.hasSecondaryCalculation(descriptor)) {
                if (!Utils.isNumber(dataExpectation)) {
                    return new ValidationResult(WARN, "Primary and secondary calculation results of " +
                        descriptor.label + "  does not match.")
                }
                if (data !== dataExpectation &&
                    Utils.toNumber(data) !== (Utils.toNumber(dataExpectation) + Utils.toNumber(dataCorrection))) {
                    return new ValidationResult(WARN, "Primary and secondary calculation results of " +
                        descriptor.label + "  does not match.")
                }
            }
        }

        return new ValidationResult(VALID)
    }

    static _validateHeadline(data) {
        return data !== "" ?
            new ValidationResult("Headline fields must be empty.", INVALID) : new ValidationResult(VALID)
    }

}

export { DescriptorValidator }
