import Utils from "../utils";
import Alert from "../alert";
import { DescriptorProps, DescriptorType } from "../../pages/admin/company/components/financialTables/schema/descriptors/models/descriptorModel";
import Assert from "../asserts";

class CalculationExpUtil {

    static NO_OP_CALC = "NO_OP"

    static fragmentsToStr(calculationArr) {
        if (calculationArr.length === 0) {
            return CalculationExpUtil.NO_OP_CALC
        }

        return calculationArr.map(frag => {
            if (Array.isArray(frag)) {
                return frag.length > 0 ? ("(" + frag.join("+") + ")") : ""
            } else {
                return frag
            }
        }).reduce((a, b) => a + b)
    }

    /**
     * @param {[]} calculationArr Required 
     * @returns {[]} not-null filtered calculation arr
     */
    static filterEmptyFragments(calculationArr) {
        const fragments = []
        for (let i = 0; i < calculationArr.length; i++) {
            const fragment = calculationArr[i];
            if (Array.isArray(fragment) && fragment.length === 0) {
                fragments.pop()
                continue
            }

            fragments.push(fragment)
        }

        return fragments
    }

    /**
     * @param {string} calculation  Required
     * @returns not-null array of fragments where there are two types of fragments.
     * Args Group in format [fieldId-1,fieldId-2,fieldId-3] and operation arg presented as string e.g. +
     */
    static strToFragments(calculation) {
        const grpups = []
        if (calculation !== CalculationExpUtil.NO_OP_CALC) {
            const calculationArr = calculation.split("");

            if (calculationArr.length === 0) {
                grpups.push([])
            } else {
                let idxClose = 0;

                while (idxClose < calculationArr.length - 1) {
                    let idxOpen = calculationArr.indexOf("(", idxClose);
                    idxClose = calculationArr.indexOf(")", idxOpen);
                    grpups.push(calculationArr.slice(idxOpen + 1, idxClose).join("").split("+"))
                    if (idxClose < calculationArr.length - 1) {
                        const sign = calculationArr[idxClose + 1]
                        if (sign !== "+" && sign !== "-" && sign !== "*" && sign !== "/") {
                            Alert.error("Unexpected fragment: " + sign + " The fragmet is part of calculation " + calculation)
                            break
                        }
                        grpups.push(sign)
                    }
                }
            }
        }

        return grpups
    }


    /**
     * @param {string} calculation  Required
     * @returns not-null array of descriptorIds 
     */
    static getIncludedIds(calculation) {
        const ids = []
        const calculationArr = calculation.split("");

        if (calculationArr.length > 0) {
            let idxClose = 0;

            while (idxClose < calculationArr.length - 1) {
                let idxOpen = calculationArr.indexOf("(", idxClose);
                idxClose = calculationArr.indexOf(")", idxOpen);
                ids.push(...calculationArr.slice(idxOpen + 1, idxClose).join("").split("+"))
            }
        }

        return ids
    }

    static isArgsGroup(calculationElement) {
        return Array.isArray(calculationElement)
    }

    /**
     * @param {string} calculation in non human readable format
     * @param {[]} flatDescriptors all descriptors of schema that the calculation is part of.
     * @returns {string} not-null 
     */
    static originalToHumanReadable(calculation, flatDescriptors) {
        for (const descriptor of flatDescriptors) {
            // trim() needs for the review diff
            calculation = calculation.replaceAll(descriptor.id, " " + descriptor.label.trim() + " ")
        }
        return calculation
    }

    /**
     * @param {string} calculation in non human readable format
     * @param {[]} flatDescriptors all descriptors of schema that the calculation is part of.
     * @returns {Component} not-null
     */
    static originalToListComponent(calculation, flatDescriptors) {

        const calcFrags = CalculationExpUtil.strToFragments(calculation)
        const listItems = []

        calcFrags.forEach(frag => {
            if (CalculationExpUtil.isArgsGroup(frag)) {
                frag.map(fieldId => {
                    listItems.push(<li key={listItems.length} >+ {flatDescriptors.find(d => d.id === fieldId).label}</li>)
                })
            } else {
                listItems.push(<li key={listItems.length}>{frag}</li>)
            }
        })

        return <ul>{listItems}</ul>
    }

    /**
     * @param {Object} descriptor Required 
     * @returns {PreparedPercentCalc | PreparedNumberCalculation | undefined} not-null
     */
    static toPreparedCalc(descriptor) {
        const calcFrags = CalculationExpUtil.strToFragments(descriptor[DescriptorProps.CALCULATION])

        const calcIds = []
        calcFrags
            .filter(frag => CalculationExpUtil.isArgsGroup(frag))
            .forEach(ids => calcIds.push(...ids))

        if (DescriptorType.isNumber(descriptor)) {
            return new PreparedNumberCalculation(calcFrags, calcIds)
        } else if (DescriptorType.isPercent(descriptor)) {
            return new PreparedPercentCalc(calcFrags, calcIds)
        } else {
            Assert.fail(`unexpected descriptor type for prepared calculation ${JSON.stringify(descriptor, null, 2)}`)
        }
    }


    /**
     * @param {[]} calcFrags Required
     * @param {{}} dynamicFields Required
     * @returns {string} not-null
     */
    static buildExpressionStrict(calcFrags, dynamicFields) {
        let exp = ""
        calcFrags.forEach(frag => {
            if (CalculationExpUtil.isArgsGroup(frag)) {
                exp += "(" + frag.map(fieldId => {
                    return Utils.toNumber(dynamicFields[fieldId])
                }).join("+") + ")"
            } else {
                exp += frag
            }
        })

        return exp
    }
}

function notCalculatabilityCheckStrict(calcEntryIds, report) {
    return calcEntryIds.some(id => !Utils.isNumber(report[id]))
}

class PreparedPercentCalc {

    constructor(calcFrags, calcIds) {
        this.calcFrags = calcFrags;
        this.calcIds = calcIds;
    }

    calculate(dynamicFields) {
        if (notCalculatabilityCheckStrict(this.calcIds, dynamicFields)) {
            return "-"
        } else {
            const res = Number(eval(CalculationExpUtil
                .buildExpressionStrict(this.calcFrags, dynamicFields)))
            if (!Utils.isNumber(res)) {
                return "-"
            }

            return (res * 100) + "%"
        }
    }
}

class PreparedNumberCalculation {
    constructor(calcFrags, calcIds) {
        this.calcFrags = calcFrags;
        this.calcIds = calcIds;
    }

    calculate(dynamicFields) {
        if (notCalculatabilityCheckStrict(this.calcIds, dynamicFields)) {
            return "-"
        } else {
            const res = Number(eval(CalculationExpUtil
                .buildExpressionStrict(this.calcFrags, dynamicFields)))

            if (!Utils.isNumber(res)) {
                return "-"
            }

            return res;
        }
    }
}

export { CalculationExpUtil }