import { ReportsUtils } from './reportsUtils'
import Alert from './alert'
import ReportUtils from './reportUtils'
import ObjCheck from './objCheck'
import { MetadataField, ReportField } from '../constnats/reportConstants'

const METADATA_PROPS_FOR_REVIEW = [
    MetadataField.CORRECTION,
    MetadataField.REPORTED_AS_LABEL,
    MetadataField.MANUALLY_CALCED,
    MetadataField.CALCS_DISABLED,
    MetadataField.EXTENDED_SEARCH,
    MetadataField.SRC
]

const DESCRIPTOR_IDS_TO_IGNORE_METADATA_REVIEW_FOR = [ReportField.TYPE, ReportField.SRC, ReportField.REPORT_TYPE]

class ReportDiffUtil {

    /**
     * @param {[]} editReports 
     * @param {[]} reviewReports 
     * @param {[]} descriptorPairs
     * @param {boolean} ignoreNonCoreData
     * @param {boolean} ignoreReportDuplications
     * @returns {[Diff]} not-null diffs
     */
    static reportsDiff(editReports, reviewReports, descriptorPairs,
        ignoreNonCoreData = false, ignoreReportDuplications = false) {
        editReports = ReportsUtils.sort(editReports)
        const diffs = []
        const reportPairs = this._mapReports(editReports, reviewReports, ignoreReportDuplications)

        for (const reportPair of reportPairs) {
            const editReport = reportPair.editReport && ReportUtils.flattenDynamicFields(reportPair.editReport)
            const reviewReport = reportPair.reviewReport && ReportUtils.flattenDynamicFields(reportPair.reviewReport)

            if (!editReport) {
                diffs.push(new Diff(this._getGetName(reviewReport), "N/A", "N/A", "Not Found", "Found"))
                continue
            } else if (!reviewReport) {
                diffs.push(new Diff(this._getGetName(editReport), "N/A", "N/A", "Found", "Not Found"))
                continue
            }

            diffs.push(...this._reportPairDiff(editReport, reviewReport, descriptorPairs, ignoreNonCoreData))
        }

        return diffs
    }

    /**
     * @returns {[]} not-null report pairs
     */
    static _mapReports(editReports, reviewReports, ignoreReportDuplications) {
        const editReportsMap = this._toMap(editReports, ignoreReportDuplications)
        const reviewReportsMap = this._toMap(reviewReports, ignoreReportDuplications)

        const pairedReviewReports = []
        const reportPairs = []

        for (const name of editReportsMap.keys()) {
            reportPairs.push(new ReportPair(editReportsMap.get(name), reviewReportsMap.get(name)))
            pairedReviewReports.push(name)
        }

        reviewReports.filter(r => !pairedReviewReports.includes(this._getGetName(r)))
            .forEach(r => reportPairs.push(new ReportPair(null, r)));


        return reportPairs;
    }

    static _toMap(reports, ignoreReportDuplications) {
        const reportsMap = new Map()

        for (const report of reports) {
            const reportName = this._getGetName(report)
            if (reportsMap.has(reportName)) {
                Alert.error(`"At least one of the branches contains more than two report for:"${reportName}`, "", false)
                if (!ignoreReportDuplications) {
                    throw new Error("Duplicated reports")
                }
            } else {
                reportsMap.set(reportName, report)
            }
        }

        return reportsMap
    }


    static _reportPairDiff(editReport, reviewReport, descriptorPairs, ignoreNonCoreData) {
        const diffs = []


        for (const descriptorPair of descriptorPairs) {
            const editBranchDesc = descriptorPair.editBranchDesc
            const reviewBranchDesc = descriptorPair.reviewBranchDesc

            const label = reviewBranchDesc !== null ? reviewBranchDesc.label : editBranchDesc.label

            //TMP fast implematation for core data checks
            if (editBranchDesc == null || reviewBranchDesc == null) {
                if (editBranchDesc === null) {
                    const prop = reviewBranchDesc.id
                    const val = reviewReport[prop]
                    if (!ObjCheck.isNullUndefinedEmptyOrDash(val)) {
                        diffs.push(new Diff(this._getGetName(reviewReport),
                            label, "ALL", "No Data", "Presented (val:" + val + ")"))
                    }


                } else {
                    const prop = editBranchDesc.id
                    const val = editReport[prop]
                    if (!ObjCheck.isNullUndefinedEmptyOrDash(val)) {
                        diffs.push(new Diff(this._getGetName(editReport),
                            label, "ALL", "Presented (val:" + val + ")", "No Data"))
                    }
                }

                continue
            }

            const editProp = editBranchDesc.id
            const reviewProp = reviewBranchDesc.id

            const editFieldVal = editReport[editProp]
            const reviewFieldVal = reviewReport[reviewProp]
            const reportName = this._getGetName(editReport)

            if (ObjCheck.isNullUndefinedEmptyOrDash(editFieldVal) &&
                ObjCheck.isNullUndefinedEmptyOrDash(reviewFieldVal)) {
                if (ignoreNonCoreData !== true) {
                    diffs.push(...MetadataDiffExtractor.extract(label, reportName, editReport, reviewReport, editProp, reviewProp))
                }
            } else {
                if (ObjCheck.isNullOrUndefined(editFieldVal)) {
                    diffs.push(new Diff(reportName, label, "ALL", "No Data", "Presented (val:" + reviewFieldVal + ")"))
                } else if (ObjCheck.isNullOrUndefined(reviewFieldVal)) {
                    diffs.push(new Diff(reportName, label, "ALL", "Presented (val:" + editFieldVal + ")", "No Data"))
                } else {
                    if (editFieldVal !== reviewFieldVal) {
                        diffs.push(new Diff(reportName, label, "value", editFieldVal, reviewFieldVal))
                    }

                    if (ignoreNonCoreData !== true) {
                        diffs.push(...MetadataDiffExtractor.extract(label, reportName, editReport, reviewReport, editProp, reviewProp))
                    }
                }
            }
        }

        return diffs
    }

    static _getGetName(report) {
        return report[ReportField.YEAR] + "/" + report[ReportField.PERIOD] +
            "/" + report[ReportField.TYPE] + "/" + report[ReportField.REPORT_TYPE]
    }
}

class MetadataDiffExtractor {

    static extract(label, reportName, editReport, reviewReport, editProp, reviewProp) {
        const diffs = []
        const editMetadata = ReportUtils.getFieldMetadataOrDefault(editReport, editProp)
        const reviewMetadata = ReportUtils.getFieldMetadataOrDefault(reviewReport, reviewProp)

        if (!DESCRIPTOR_IDS_TO_IGNORE_METADATA_REVIEW_FOR.includes(editProp)) {
            for (const metadataProp of METADATA_PROPS_FOR_REVIEW) {
                const editData = MetadataDiffExtractor._normalizeVal(editMetadata?.[metadataProp])
                const reviewData = MetadataDiffExtractor._normalizeVal(reviewMetadata?.[metadataProp])
                if (editData !== reviewData) {
                    if (metadataProp === MetadataField.REPORTED_AS_LABEL &&
                        ObjCheck.isNullUndefinedEmptyOrDash(editData) &&
                        ObjCheck.isNullUndefinedEmptyOrDash(reviewData)) {
                        console.log("Skipping empty labels diff")
                    } else {
                        diffs.push(new Diff(reportName, label, metadataProp,
                            editData, reviewData))
                    }
                }
            }
        }

        return diffs
    }

    static _normalizeVal(val) {
        if (val === null) {
            return undefined
        } else if (typeof val === 'string') {
            return val.trim()
                .replace(/\s+/g, ' ')
                .replace(/\s*\/\s*/g, "/")
                .replace("–", "-")
                .replace("−", "-")
                .replace("\u2011", "-")
                .replace("\u2010", "-")

        } else {
            return val
        }
    }
}

class Diff {
    static ERROR = "ERROR"
    static WARNING = "WARNING"

    /**
     * @param {string} name Fomat:{YEAR}/{PERIOD}/{VERSION}/{FORMAT} Required
     * @param {*} fieldLabel 
     * @param {*} property 
     * @param {*} edit 
     * @param {*} review 
     * @param {*} type 
     */
    constructor(name, fieldLabel, property, edit, review, type = Diff.ERROR) {
        this.name = name
        this.fieldLabel = fieldLabel
        this.property = property
        this.edit = edit
        this.review = review
        this.type = type
    }
}

class ReportPair {

    /**
     * @param editReport  Optional 
     * @param reviewReport Optional 
     */
    constructor(editReport, reviewReport) {
        this.editReport = editReport
        this.reviewReport = reviewReport
    }
}

export { ReportDiffUtil, Diff } 