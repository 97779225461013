import React from 'react'
import { Grid, Menu, Popup } from 'semantic-ui-react'
import { Button } from 'semantic-ui-react'
import { ConfigProps } from '../../../../../../components/financialTables/configuration/constants';

class Header {
    constructor(fieldDescriptor, level) {
        this.fieldDescriptor = fieldDescriptor;
        this.level = level;
    }
}

/**
 * 
 * @param {Array} descriptors Required
 * @param {number} level Required
 * @returns {Array<Header>}
 */
function columnItems(descriptors, level) {
    const items = []
    for (const descriptor of descriptors) {
        items.push(new Header(descriptor, level))
        items.push(...columnItems(descriptor.subFields, level + 1))
    }

    return items
}


function getHeader(level, label) {
    return <Popup size='tiny' content={label} trigger={
        <div className={"tableHeaderLevel_" + level}>{label}</div>
    } />
}


function HeaderColumn({ descriptors, position, setHighlightedRows, highlightedRows, readOnlyMode, addReport, advancedTableCfg }) {
    /**
     * @returns {string} returns the additional class names based on the advanced settings
     */
    const getAdditionalHeaderColumnClasses = () => {
        const headerFontSizeClass = `headerFontSize_${advancedTableCfg[ConfigProps.HEADER_COLUMN_FONT_SIZE]}`
        const headerWidthClass = `headerWidth_${advancedTableCfg[ConfigProps.HEADER_COLUMN_WIDTH]}`
        return `${headerFontSizeClass} ${headerWidthClass}`
    }

    const items = columnItems(descriptors, 0)
    return <Grid.Column style={{ position }} className={`tableHeader ${getAdditionalHeaderColumnClasses()}`} textalign='left'>
        <Menu fluid vertical className={advancedTableCfg[ConfigProps.VERTICALS] ? "" : "noVerticals"}>
            {items.map((i, idx) =>
                <Menu.Item
                    onMouseEnter={() => setHighlightedRows([idx + 1])}
                    onMouseLeave={() => setHighlightedRows([])}
                    key={idx}
                    className={`header ${advancedTableCfg[ConfigProps.HORIZONTALS] ? "" : "borderless"}`}
                    style={highlightedRows.includes(idx + 1) ? Object.assign({}, i.fieldDescriptor.style, { backgroundColor: "#ffff99" }) : i.fieldDescriptor.style} >
                    {getHeader(i.level, i.fieldDescriptor.label)}
                </Menu.Item>)}
            {!readOnlyMode &&
                <Button.Group className='createReportBtn' size="mini" color='blue'>
                    <Button primary
                        size="mini"
                        onClick={() => addReport()}>Add</Button>
                </Button.Group>}
        </Menu>
    </Grid.Column>

}

export default HeaderColumn