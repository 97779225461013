import './tableActionButton.styles.css';

const TableActionButton = ({ children, className, ...rest }) => {
    return (
        <button className={`tableActionButton ${className ? className : ''}`} {...rest}>
            {children}
        </button>
    );
};

export default TableActionButton;
