import React from 'react';
import { Header } from 'semantic-ui-react'


class AboutUs extends React.Component {

    render() {
        return (
            <>
                <Header as="h2" textAlign='center'>About Us</Header>
                <div>
                    <p>
                        3D StockPicker was founded by Jörg Geyer, who has
                        experience in the financial markets for nearly 20 years.
                        He follows a fundamental approach by analyzing the financial
                        reports of stock market-listed companies. His focus was
                        on the income statements, Balance sheets, Cash-flow
                        statements, and the KPIs of the companies.
                    </p>
                    <p>
                        During this period, he tried many investing tools but did
                        not find one to give him the information and the format he
                        needed. The main problem is that the tools adjust and
                        rearrange the numbers from the financial statements so that
                        the companies are comparable. This approach is, of course,
                        also helpful, but a big disadvantage is that a lot of
                        company-specific facts and details get lost.
                    </p>
                    <p>
                        To catch the company-specific information and
                        developments better, he made his own Excel sheets and
                        developed them further over time. At some point, the
                        features, the amount of data, and the complexity of the
                        sheets were too high, and it was necessary to develop a
                        special tool. This was the beginning of 3D StockPicker.
                    </p>

                </div>
            </>
        );
    }
}


export default AboutUs


